import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import ReactHtmlParser from "react-html-parser";

const CardDay = ({
  titulo,
  descripcion,
  imagen,
  setDiaActual,
  url,
  id,
  eliminarActual,
  eliminar,
}) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia component="img" height="140" image={url} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {titulo}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <div dangerouslySetInnerHTML={{ __html: descripcion }} />
        </Typography>
      </CardContent>
      <CardActions>
        {eliminar ? null : (
          <Button
            size="small"
            onClick={() => {
              eliminarActual(id);
              console.log("####CARD: " + id);
            }}
          >
            Eliminar
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default CardDay;
