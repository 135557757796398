/* React */
import { useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";

/* Components & dependencies */
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

/* Service */
import CarruselService from "../../../services/Carrusel.service";
import styles from "../layout.module.css";

const CarruselTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [carrusel, setCarrusel] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    loadCarrusel();
  }, [page, limit]);

  const loadCarrusel = async () => {
    setLoading(true);
    try {
      const results = await CarruselService.list(limit, page * limit);
      setCarrusel(results.data);
      setTotal(results.total);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checarEstado = (objeto) => {
    if (objeto.isActive === true) {
      return (
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
          onClick={async () => {
            objeto.isActive = false;
            await CarruselService.update(objeto);
            loadCarrusel();
          }}
          label=""
          style={{ marginLeft: "0.3%" }}
        />
      );
    } else {
      return (
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} />}
          onClick={async () => {
            objeto.isActive = true;
            await CarruselService.update(objeto);
            loadCarrusel();
          }}
          label=""
          style={{ marginLeft: "0.3%" }}
        />
      );
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className={styles.recentorders} style={{ display: "grid" }}>
          <div style={{ overflowX: "auto", width: "100%" }}>
            <table>
              <thead>
                <tr>
                  <td>
                    <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                      CARRUSEL
                    </h1>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => navigate(`nuevo`)}
                    >
                      Nuevo
                    </Button>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>TITULO</td>
                  <td style={{ fontWeight: "bold" }}>SUBTITULO</td>
                  <td style={{ fontWeight: "bold" }}>ESTADO</td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {carrusel.map((record, index) => {
                  return (
                    <tr key={`${record.id}_${record.titulo}`}>
                      <td>{record.titulo}</td>
                      <td>{record.subtitulo}</td>
                      <td>{checarEstado(record)}</td>
                      <td>
                        <span
                          onClick={() => navigate(`editar/${record.id}`)}
                          className="material-icons-sharp"
                        >
                          {" "}
                          edit{" "}
                        </span>
                      </td>
                      <td>
                        <span
                          className="material-icons-sharp"
                          onClick={() => navigate(`eliminar/${record.id}`)}
                        >
                          {" "}
                          delete{" "}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            style={{ color: "var(--color-dark-variant)" }}
            component="div"
            classes="recent-orders"
            labelRowsPerPage="Items por pagina"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default CarruselTable;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
