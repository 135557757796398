import { width } from "@mui/system";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

const HeaderPublicLayout = () => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [usuario, setUsuario] = useState(null);
  const { logout } = useContext(AuthContext);

  const [inicio, setInicio] = useState("active");
  const [paquetes, setPaquetes] = useState("");
  const [blog, setBlog] = useState("");
  const [hoteles, setHoteles] = useState("");
  const [dashboard, setDashboard] = useState("");
  const [iniciarSesion, setIniciarSesion] = useState("");

  const activeInicio = () => {
    setInicio("active");
    setPaquetes("");
    setHoteles("");
    setBlog("");
    setDashboard("");
    setIniciarSesion("");
  };

  const activePaquetes = () => {
    setInicio("");
    setPaquetes("active");
    setHoteles("");
    setBlog("");
    setDashboard("");
    setIniciarSesion("");
  };

  const activeHoteles = () => {
    setInicio("");
    setPaquetes("");
    setHoteles("active");
    setBlog("");
    setDashboard("");
    setIniciarSesion("");
  };

  const activeBlog = () => {
    setInicio("");
    setPaquetes("");
    setHoteles("");
    setBlog("active");
    setDashboard("");
    setIniciarSesion("");
  };

  const activeDashboard = () => {
    setInicio("");
    setPaquetes("");
    setHoteles("");
    setBlog();
    setDashboard("active");
    setIniciarSesion("");
  };

  const activeIniciarSesion = () => {
    setInicio("");
    setPaquetes("");
    setHoteles("");
    setBlog("");
    setDashboard("");
    setIniciarSesion("active");
  };

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let url = window.location.href;
    if (currentUser == undefined) {
    } else {
      setUsuario(currentUser);
    }
    if (url.includes("login")) {
      setActive("login");
    } else if (url.includes("paquete")) {
      setActive("paquete");
    } else if (url.includes("blog")) {
      setActive("blog");
    } else if (url.includes("hoteles")) {
      setActive("hoteles");
    } else if (url.includes("dashboard")) {
      setActive("dashboard");
    } else {
      setActive("");
    }

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
      if (direction === "down") {
        let element;
        element = document.querySelector("#searchLupa");
        element.style.display = "none";
      }
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener

    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  const displayBurgerMenu = () => {
    let element;
    element = document.querySelector("#burgerMenu");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  const navega = (direction) => {
    navigate(direction);
    displayBurgerMenu();
  };

  const displayBuscar = () => {
    let element;
    element = document.querySelector("#searchLupa");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  const displayUsuario = () => {
    let element;
    element = document.querySelector("#usuarioInformation");
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  const liLogin = () => {
    if (currentUser == undefined) {
      return (
        <li className={iniciarSesion}>
          <a
            className=""
            onClick={() => {
              navigate(`/login`);
              activeIniciarSesion();
            }}
          >
            Iniciar sesión
          </a>
        </li>
      );
    }

    return (
      <>
        <li className={dashboard}>
          <a
            className=""
            onClick={() => {
              navigate("/dashboard");
              activeDashboard();
            }}
          >
            Mis paquetes
          </a>
        </li>
        <li className={iniciarSesion}>
          <a
            className=""
            onClick={() => {
              salir();
            }}
          >
            Salir
          </a>
        </li>
      </>
    );
  };

  const botonUsuario = () => {
    if (currentUser != undefined) {
      return (
        <div
          className="nav-item dropdown nav-item-left"
          style={{ marginRight: "-5%" }}
        >
          <a
            href="javascript:void(0)"
            className="btn btn-xs btn-primary d-none d-lg-inline-block"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => displayUsuario()}
          >
            <i className="fa fa-user mr-0" aria-hidden="true" />
          </a>
          <div
            id="usuarioInformation"
            className="dropdown-menu dropdown-menu-right rounded-0 show"
            style={{ display: "none" }}
          >
            <h3>Bienvenido </h3>
            <li className="">
              <a className="" href="javascript:void(0)">
                {currentUser.nombre}
              </a>
            </li>
            <div className="input-group-append">
              <button
                className="input-group-text border-0 rounded-200 btn bg-primary"
                type="submit"
                onClick={() => salir()}
                style={{ marginTop: "-18%", marginLeft: "90%" }}
              >
                <span className="text-white text-uppercase font-weight-bold">
                  <i className="fa fa-power-off mr-0" aria-hidden="true" />
                </span>
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <>
      <div className="headers">
        {/*-- HEADER MOVIL--*/}
        <div className="headerMovil">
          <nav
            className={`nav-menuzord navbar-sticky ${
              scrollDirection === "down" ? "navbar-scrollUp" : ""
            }`}
            style={{ opacity: 1 }}
          >
            <div
              className="containerPublic clearfix"
              style={{ display: "block !important" }}
            >
              <div id="menuzord" className="menuzord menuzord-responsive">
                <a
                  className="showhide"
                  href="javascript:void(0)"
                  style={{ display: "inline-block" }}
                  onClick={() => displayBurgerMenu()}
                >
                  <em />
                  <em />
                  <em />
                </a>
                <a
                  href="javascript:void(0)"
                  className="menuzord-brand"
                  onClick={() => navigate("/")}
                >
                  {/* <img
                    className=""
                    data-src="/assets/img/logo-icon.png"
                    src="/assets/img/logo-icon.png"
                    alt="logo-img"
                    style={{ width: "3rem", height: "3rem" }}
                  /> */}
                  <h1 style={{ marginLeft: "-35px" }}>CHIMALLI</h1>
                </a>
                <div className="nav-item dropdown nav-item-left mr-0">
                  <a
                    className="btn btn-xs btn-primary d-none d-lg-inline-block"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    href="javascript:void(0)"
                  >
                    <i className="fa fa-search mr-0" aria-hidden="true" />
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right rounded-0 show"
                    style={{ display: "none" }}
                  ></div>
                </div>
                <ul
                  id="burgerMenu"
                  className="menuzord-menu menuzord-right menuzord-indented scrollable remove-menuzord"
                  style={{ maxHeight: 400, display: "none" }}
                >
                  <li className="">
                    <a
                      className=""
                      onClick={() => navigate(`/`)}
                      href="javascript:void(0)"
                    >
                      Inicio
                    </a>
                  </li>
                  <li className="">
                    <a
                      onClick={() => navigate(`/paquetes`)}
                      href="javascript:void(0)"
                    >
                      Paquetes
                    </a>
                    <div
                      className="megamenu drop-up"
                      style={{
                        left: "50%",
                        display: "none",
                        width: "55%",
                        right: 0,
                      }}
                    ></div>
                  </li>
                  {/* <li className="">
                    <a
                      onClick={() => navigate(`/hoteles`)}
                      href="javascript:void(0)"
                    >
                      Hoteles
                    </a>
                    <div
                      className="megamenu drop-up"
                      style={{
                        left: "50%",
                        display: "none",
                        width: "55%",
                        right: 0,
                      }}
                    ></div>
                  </li> */}
                  <li className="">
                    <a
                      onClick={() => navigate(`/blog`)}
                      href="javascript:void(0)"
                    >
                      Blog
                    </a>
                  </li>
                  {liLogin()}
                  {/* <li class="">
        <a href="components.html">Components</a>
      </li> */}
                  <li className="py-4">
                    <form
                      className="d-lg-none"
                      action="index.html"
                      method="post"
                    ></form>
                  </li>
                  <li className="scrollable-fix" />
                </ul>
              </div>
            </div>
          </nav>
        </div>
        {/*-- end header movil--*/}

        {/*-- HEADER DESKTOP--*/}
        <div className="headerDesktop">
          <header className="header" id="pageTop">
            {/* Menu Zord */}
            <nav
              className={`nav-menuzord navbar-sticky ${
                scrollDirection === "down" ? "navbar-scrollUp" : ""
              }`}
            >
              <div
                className="containerPublic clearfix"
                style={{ display: "block !important" }}
              >
                <div id="menuzord" className="menuzord ">
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate("/")}
                    className="menuzord-brand"
                    style={{ padding: "0.2rem" }}
                  >
                    <img
                      className="lazyestload"
                      data-src="/assets/img/logoPNG.png"
                      src="/assets/img/logoPNG.png"
                      alt="CHIMALLI"
                      style={{ height: "88px" }}
                    />

                    {/* <h1 style={{ marginLeft: "-50px" }}>CHIMALLI</h1> */}
                  </a>
                  {botonUsuario()}
                  <ul className="menuzord-menu menuzord-right">
                    <li className={inicio}>
                      <a
                        className=""
                        onClick={() => {
                          navigate(`/`);
                          activeInicio();
                        }}
                      >
                        Inicio
                      </a>
                    </li>
                    <li className={paquetes}>
                      <a
                        onClick={() => {
                          navigate(`/paquetes`);
                          activePaquetes();
                        }}
                      >
                        Paquetes
                      </a>
                      <div
                        className="megamenu drop-up"
                        style={{
                          left: "50%",
                          display: "none",
                          width: "55%",
                          right: 0,
                        }}
                      ></div>
                    </li>
                    {/* <li className={hoteles}>
                      <a
                        onClick={() => {
                          navigate(`/hoteles`);
                          activeHoteles();
                        }}
                      >
                        Hoteles
                      </a>
                      <div
                        className="megamenu drop-up"
                        style={{
                          left: "50%",
                          display: "none",
                          width: "55%",
                          right: 0,
                        }}
                      ></div>
                    </li> */}
                    <li className={blog}>
                      <a
                        onClick={() => {
                          navigate(`/blog`);
                          activeBlog();
                        }}
                      >
                        Blog
                      </a>
                    </li>
                    {liLogin()}
                    <li className="scrollable-fix" />
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        </div>
        {/*-- end header desktop--*/}
      </div>
    </>
  );
};

export default HeaderPublicLayout;
