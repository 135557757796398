/* React */
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, MouseEvent, ChangeEvent } from "react";

/* Components & dependencies */
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { toast } from "react-toastify";
import PagoDetalles from "./PagoDetalles";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";

/* Service */
import PagoService from "../../../services/Pago.service";
import styles from "../layout.module.css";
import PaqueteService from "../../../services/Paquete.service";
import { valueToPercent } from "@mui/base";

const TablaPagos = () => {
  const [pagos, setPagos] = useState([]);
  const [pagosArray, setPagosArray] = useState([]);
  const [pagosTodos, setPagosTodos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [idPaquete, setIdPaquete] = useState();
  const [paquetes, setPaquetes] = useState([]);

  useEffect(() => {
    getPagos();
  }, [page, limit]);

  const getPagos = async () => {
    setLoading(true);
    try {
      const data = await PagoService.list(limit, page * limit);
      const p = await PaqueteService.getAll();
      setPaquetes(p);
      setPagos(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const corte = async () => {
    setLoading(true);
    try {
      const data = await PagoService.getAll();
      setPagosTodos(data);
      console.log("-------------" + data);
      data.forEach((pago) => {
        const newPago = {
          nombre: pago.usuario.nombre,
          correo: pago.usuario.email,
          direccion: pago.usuario.direccion,
          precio: pago.precio,
          status: pago.status,
        };
        setPagosArray((pagosArray) => [...pagosArray, newPago]);
      });
      console.log("----0" + pagosArray);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = React.useState([]);

  const limpiarPagos = () => {
    setPagos([]);
  };

  const setFiltrosCiudades = async (event) => {
    limpiarPagos();
    try {
      const {
        target: { value },
      } = event;
      setIdPaquete(value);
      let a = 0;
      console.log("cantidad de paquetes antes: " + pagos.lenght);
      for (const record of value) {
        //toast.success("los id que hay: "+record+"num"+a);
        a += 1;
        const data = await PagoService.findPagosByPaquete(record);
        const pagosNew = pagos.concat(data.data);
        setPagos(pagosNew);
        setTotal(data.total + total);
        console.log(
          "a: " + a + " data: " + pagos.length + " pgosNew" + pagosNew.length
        );
      }
      /* const data = await PagoService.findPagosByPaquete(value);
      const pagosNew = pagos.concat(data.data);
        setPagos(pagosNew);
        setTotal(data.total+total);
      toast.success("id"+value);*/
      setPersonName(typeof value === "string" ? value.split(",") : value);
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const handleChange = (event, newValue) => {
    setIdPaquete(newValue);
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className={styles.recentorders} style={{ display: "grid" }}>
          <div style={{ overflowX: "auto" }}>
            <div>
              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left", width: "50%" }}>
                      <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                        RESERVAS
                      </h1>
                    </th>

                    <th style={{ textAlign: "right" }}>
                      <Button
                        variant="outlined"
                        color="warning"
                        onClick={() => navigate(`corte/${idPaquete}`)}
                        style={{
                          height: "53.13px",
                          marginTop: "1.3rem",
                        }}
                      >
                        CORTE
                      </Button>
                    </th>

                    <th style={{ textAlign: "left", width: "25%" }}>
                      <p>Buscar </p>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={setFiltrosCiudades}
                        input={<OutlinedInput label="Paquete" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ff891e !important",
                          },
                        }}
                        style={{ width: "200px" }}
                      >
                        {paquetes.map((record) => (
                          <MenuItem key={record.id} value={record.id}>
                            <Checkbox
                              checked={personName.indexOf(record.id) > -1}
                            />
                            <ListItemText
                              primary={record.titulo}
                              secondary={record.id}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <table>
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>#</td>
                  <td style={{ fontWeight: "bold" }}>NOMBRE</td>
                  <td style={{ fontWeight: "bold" }}>CORREO</td>
                  <td style={{ fontWeight: "bold" }}>PAQUETE</td>
                  <td style={{ fontWeight: "bold" }}>PAGO</td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      minWidth: "7.5rem",
                    }}
                  >
                    VER DETALLE{" "}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "center" }}>
                    ESTADO
                  </td>
                  <td style={{ fontWeight: "bold" }}>ELIMINAR</td>
                </tr>
              </thead>
              <tbody>
                {pagos.map((record, index) => {
                  return <PagoDetalles id={record.id} data={record} />;
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            style={{ color: "var(--color-dark-variant)" }}
            component="div"
            classes="recent-orders"
            labelRowsPerPage="Items por pagina"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default TablaPagos;
