/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";

/* Servicios */
import AvisoService from "../../../services/Aviso.service";

import styles from "../layout.module.css";

const TablaAviso = () => {
  const [aviso, setAviso] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState({});
  const myTheme = createTheme({});

  useEffect(() => {
    getAviso();
  }, []);

  const getAviso = async () => {
    setLoading(true);
    try {
      const av = await AvisoService.get();
      setAviso(av[0]);
      console.log("----" + av[0].titulo);

      const contentHTML = convertFromHTML(av[0].cuerpo);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));

      setContenido(content);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAviso = async () => {
    if (!aviso.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (!aviso.cuerpo) {
      toast.info("Error, tienes que colocar texto al aviso de privacidad");
    } else {
      setLoading(true);
      try {
        const dataAviso = aviso;
        const updateAviso = await AvisoService.update(dataAviso);
        toast.success("Aviso actualizada con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getAviso();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.recentorders}>
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <h1
                    className="card-title"
                    style={{ marginRight: "20px", marginBottom: "20px" }}
                  >
                    AVISO DE PRIVACIDAD
                  </h1>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      placeholder="Nombre del producto"
                      value={aviso.titulo}
                      onChange={(e) =>
                        setAviso({
                          ...aviso,
                          titulo: e.target.value,
                        })
                      }
                      className="form-control"
                      style={{
                        width: "-webkit-fill-available",
                        backgroundColor: "#dce1eb",
                        padding: "1.5%",
                        marginLeft: "-1%",
                        width: "50%",
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">
                    <ThemeProvider theme={myTheme}>
                      <MUIRichTextEditor
                        label="Descripcion del paquete"
                        controls={[
                          "italic",
                          "underline",
                          "strikethrough",
                          "highlight",
                          "undo",
                          "redo",
                          "link",
                          "numberList",
                          "bulletList",
                          "quote",
                          "code",
                          "clear",
                          // "save"
                        ]}
                        // onSave={save}
                        inlineToolbar={true}
                        onChange={(value) => {
                          console.log(value.getCurrentContent());
                          setAviso({
                            ...aviso,
                            cuerpo: stateToHTML(value.getCurrentContent()),
                          });
                        }}
                        defaultValue={contenido}
                      />
                    </ThemeProvider>
                  </td>
                </tr>
                <tr>
                  <div
                    className="col-lg-12"
                    style={{ textAlign: "center", marginTop: "3%" }}
                  >
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => updateAviso()}
                    >
                      Guardar
                    </Button>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaAviso;
