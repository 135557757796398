import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";

//Un array con los nombres e iconos de las opciones
const icons = [
  { name: "Home", icon: <HomeIcon /> },
  { name: "Star", icon: <StarIcon /> },
  { name: "Favorite", icon: <FavoriteIcon /> },
];

// Un componente personalizado para mostrar el icono y el nombre de cada opción
const Option = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const IconSelector = ({ changeIcon }) => {
  // Un estado para guardar el icono seleccionado
  const [selectedIcon, setSelectedIcon] = React.useState(null);

  const handleIcon = (newValue) => {
    setSelectedIcon(newValue);
    changeIcon(newValue);
  };
  return (
    <div>
      <h1>Selecciona un icono</h1>
      <Autocomplete
        // El valor seleccionado se guarda en el estado
        value={selectedIcon}
        // Se actualiza el estado cuando cambia la selección
        onChange={(event, newValue) => {
          handleIcon(newValue);
        }}
        // Las opciones son los elementos del array icons
        options={icons}
        // La propiedad que se usa para filtrar las opciones según lo que escriba el usuario
        getOptionLabel={(option) => option.name}
        // El componente que se usa para mostrar cada opción, usando el componente personalizado Option
        renderOption={(props, option) => (
          <Option {...props}>
            {option.icon}
            {option.name}
          </Option>
        )}
        // El componente que se usa para mostrar el campo de texto
        renderInput={(params) => <TextField {...params} label="Buscar icono" />}
      />
    </div>
  );
};

export default IconSelector;
