import { IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const Camas = ({ camas, handleCamas }) => {
  const handleCama = (index, data) => {
    let cama = camas.map((c, indexc) => {
      if (indexc !== index) {
        return c;
      }
      let camaNuevo = { ...c };
      camaNuevo.cama = data;
      return camaNuevo;
    });

    handleCamas(cama);
  };

  const handleOcupantes = (index, data) => {
    let cama = camas.map((c, indexc) => {
      if (indexc !== index) {
        return c;
      }
      let camaNuevo = { ...c };
      camaNuevo.ocupantes = data;
      return camaNuevo;
    });

    handleCamas(cama);
  };

  const addCama = () => {
    let cama = camas.slice();
    cama.push({ cama: "individual", ocupantes: 1 });
    handleCamas(cama);
  };
  const deleteCama = (index) => {
    let cama = camas.filter((c, indexc) => indexc !== index);

    handleCamas(cama);
  };

  return (
    <>
      {camas.map((cama, index) => (
        <div key={index} style={{ padding: "0.5rem" }}>
          <TextField
            id="filled-basic"
            label="Cama"
            variant="filled"
            value={cama?.cama}
            onChange={(event) => handleCama(index, event.target.value)}
          />
          <TextField
            id="filled-basic"
            label="Ocupantes"
            variant="filled"
            value={cama?.ocupantes}
            onChange={(event) => handleOcupantes(index, event.target.value)}
          />
          <IconButton aria-label="delete" onClick={() => deleteCama(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <div style={{ padding: "0.5rem" }}>
        <ColorButton onClick={addCama}>Agregar cama</ColorButton>
      </div>
    </>
  );
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#e3036d",
  backgroundColor: "white",
  borderColor: "#e3036d",
}));

export default Camas;
