import React, { useEffect, useState,useContext } from "react";
import PaqueteService from "../../../services/Paquete.service";
import Titulo from "./Titulo";
import AvisoPrivacidadService from "../../../services/Aviso.service";

const AvisoPrivacidad = () => {
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState([]);
  
  useEffect(() => {
    getAviso();
    window.scrollTo(0, 0);
  }, []);


  const getAviso = async () => {
    
    try {
      const data =await AvisoPrivacidadService.get();
        setAviso(data[0]);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div className="main-wrapper packages-grid">
      <Titulo />
      <section className="bg-smoke py-10">
        <div className="containerPublic">
          <div className="row">            
            <div className="col-md-12 col-lg-12 mb-5">
            <p style={{textAlign:"justify"}}
              dangerouslySetInnerHTML={{
                __html: aviso.cuerpo,
              }}
            ></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AvisoPrivacidad;
