/* React */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/* Componentes y dependencias */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Grid,
} from "@mui/material";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";
import { Delete, Visibility } from "@material-ui/icons";
import styles from "../layout.module.css";

// import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import Preloader from "../../utils/Preloader";
import { toast } from "react-toastify";
import * as bcrypt from "bcryptjs";
import { AuthContext } from "../../../context/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";

/* Servicios */
import PagoService from "../../../services/Pago.service";
import PaqueteService from "../../../services/Paquete.service";
import S3Service from "../../../services/S3.service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  border: "1px",
  boxShadow: 24,
  p: 4,
};

const PagoDetalles = ({ id, data }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pago, setPago] = useState(data);
  const [paquete, setPaquete] = useState(data);
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const { currentUser } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [openE, setOpenE] = useState(false);
  const [visibleE, setVisibleE] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const [update, setUpdate] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    visibleCheck(false);
  };

  const handleClose = () => setOpen(false);

  const handleOpenE = () => {
    setOpenE(true);
  };

  const handleCloseE = () => setOpen(false);

  useEffect(() => {
    loadPago();
  }, [update]);

  const onUpdate = () => {
    setUpdate(!update);
  };

  const visibleCheck = (valor) => {
    loadPago();
    setVisible(valor);
    document.getElementById("root").style.filter = "blur(1px)";
    if (valor == false) {
      document.getElementById("root").style.filter = "blur(0px)";
    }
  };

  const loadPago = async () => {
    setLoading(true);
    try {
      const result = await PagoService.getById(id);
      const paq = await PaqueteService.getById(result.paquete.id);
      setPago(result);
      setPaquete(paq);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const estado = (state) => {
    if (state == "EnProceso") {
      return (
        <>
          <Badge
            badgeContent={"EnProceso"}
            color="warning"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          ></Badge>
        </>
      );
    }
    if (state == "Confirmado") {
      return (
        <>
          <Badge badgeContent={"Confirmado"} color="success"></Badge>
        </>
      );
    }
    if (state == "Cancelado") {
      return (
        <>
          <Badge badgeContent={"Cancelado"} color="error"></Badge>
        </>
      );
    }
  };

  const confirmarPago = async () => {
    try {
      Swal.fire({
        target: document.getElementById("form-modal"),
        icon: "warning",
        title: "¿Estas seguro de confirmar la reserva?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          pago.status = "Confirmado";
          PagoService.update(pago)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
        }
      });
      onUpdate();
    } catch (error) {
      toast.error(error);
    }
  };

  const cancelarPago = async () => {
    try {
      Swal.fire({
        target: document.getElementById("form-modal"),
        icon: "warning",
        title: "¿Estas seguro de cancelar la reserva?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Sí",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          pago.status = "Cancelado";
          PagoService.update(pago)
            .then((response) => {})
            .catch((e) => {
              console.log(e);
            });
        }
      });
      onUpdate();
    } catch (error) {
      toast.error(error);
    }
  };

  const eliminar = async (id) => {
    try {
      const validPass = await bcrypt.compare(
        password.password,
        currentUser.contraseña
      );
      if (validPass) {
        navigate(`eliminar/${pago.id}`);
        //const paqueteBorrado = await PagoService.remove(id);
        //toast.success("Corte realizado"+paqueteBorrado.mensaje);
      } else {
        toast.error("Contraseña incorrecta");
      }
    } catch {
    } finally {
      handleCloseE();
      onUpdate();
    }
  };

  const eliminarPago = async (id) => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estas seguro de eliminar el pago?",

        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            eliminar(id);
          } catch {
          } finally {
            //window.location.reload();
          }
        }
      });
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  return (
    <>
      {loading && pago != undefined ? (
        <Preloader />
      ) : (
        <tr key={id}>
          <th scope="row">{id}</th>
          <td>{pago.usuario.nombre}</td>
          <td>{pago.usuario.email}</td>
          <td>
            {pago.paquete.id}, {paquete.titulo}{" "}
          </td>
          <td>{pago.precio}</td>
          <td style={{ alignItems: "center", textAlign: "center" }}>
            <IconButton
              aria-label="ver"
              onClick={() => {
                visibleCheck(!visible);
                handleOpen();
              }}
            >
              <Visibility />
            </IconButton>
          </td>
          <td style={{ alignItems: "center", textAlign: "center" }}>
            {estado(pago.status)}
          </td>
          <td>
            {" "}
            <IconButton
              aria-label="delete"
              onClick={() => navigate(`eliminar/${pago.id}`)}
            >
              <Delete />
            </IconButton>
          </td>
        </tr>
      )}

      <Modal
        id="form-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: "absolute" }}
      >
        <Box
          sx={style}
          style={
            matches
              ? {
                  overflow: "auto",
                  textOverflow: "ellipsis",
                  maxHeight: "700px",
                }
              : {
                  overflow: "auto",
                  textOverflow: "ellipsis",
                  maxHeight: "700px",
                  width: "100%",
                }
          }
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div style={{ width: "100%" }} className={styles.row}>
              <div
                style={{
                  marginBottom: "2%",
                }}
              >
                Información sobre el pago
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "-12%",
                  marginRight: "-3%",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    handleClose();
                    visibleCheck(false);
                  }}
                  style={{ padding: "3%" }}
                >
                  X
                </button>
              </div>
            </div>
          </Typography>
          <CModalBody style={{ marginTop: "3%" }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        Cliente
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        {pago.usuario.nombre} {pago.usuario.apellido}
                      </p>
                    </TableCell>

                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        Correo
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>{pago.usuario.email}</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        Telefono
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>{pago.usuario.telefono}</p>
                    </TableCell>

                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        Direccion
                      </p>
                    </TableCell>

                    <TableCell>
                      <p>
                        {pago.usuario.calle}, {pago.usuario.colonia},{" "}
                        {pago.usuario.ciudad}, {pago.usuario.estado},{" "}
                        {pago.usuario.cp}, {pago.usuario.pais}
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        Paquete
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        {" "}
                        {paquete.titulo}, {paquete.ciudad}
                      </p>
                    </TableCell>

                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        Reserva
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>Adultos: 2</p>
                      <p>Extra: {pago.paquete.extra}</p>
                      <p>Niños: {pago.paquete.nino}</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>Tipo</p>
                    </TableCell>
                    <TableCell>
                      <p>{pago.paquete.tipo}</p>
                    </TableCell>

                    <TableCell>
                      <p style={{ fontWeight: "bold", color: "black" }}>Pago</p>
                    </TableCell>
                    <TableCell>
                      <p>${pago.precio}</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <p style={{ fontWeight: "bold", color: "black" }}>
                            Estado
                          </p>
                        </Grid>
                        <Grid item xs={4}>
                          {estado(pago.usuario.state)}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <p>
                        <Button
                          aria-label="delete"
                          color="success"
                          onClick={() => confirmarPago(id)}
                        >
                          Confirmar
                        </Button>
                      </p>
                    </TableCell>

                    <TableCell>
                      <p>
                        <Button
                          aria-label="delete"
                          color="error"
                          onClick={() => cancelarPago(id)}
                        >
                          Cancelar
                        </Button>
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </CModalBody>
        </Box>
      </Modal>

      <Modal
        open={openE}
        onClose={handleCloseE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Eliminar
          </Typography>
          <Typography>
            Si continua se eliminaran reserva.
            {paquete.titulo}.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <table className="table">
              <tbody>
                <tr>
                  <th>Contraseña</th>
                  <th>
                    <input
                      onChange={(e) =>
                        setPassword({
                          ...password,
                          password: e.target.value,
                        })
                      }
                      value={password.password}
                      className={`form-control`}
                      type="password"
                      style={{
                        width: "-webkit-fill-available",
                        backgroundColor: "#dce1eb",
                        padding: "2%",
                        marginLeft: "-1%",
                        width: "90%",
                      }}
                    />
                  </th>
                  <th>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => eliminar(pago.id)}
                    >
                      ELIMINAR
                    </Button>
                  </th>
                </tr>
              </tbody>
            </table>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default PagoDetalles;
