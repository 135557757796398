/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import useMediaQuery from "@mui/material/useMediaQuery";
/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import styles from "../layout.module.css";

const EditarBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [categoria, setCategoria] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [etiquetas, setEtiquetas] = useState("");
  const [contenido, setContenido] = useState("");
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    getNoticia();
    getCategorias();
  }, []);

  const getNoticia = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.getById(id);
      console.log(`La imagen es: ${data[0].imagen}`);
      setNoticia(data[0]);
      const contentHTML = convertFromHTML(data[0].cuerpoNoticia);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
      console.log("cateegoria: " + data[0].categoria);

      if (data[0].imagen) {
        const imagen = await S3Service.get(data[0].imagen);
        console.log(imagen.result);
        setImagen(imagen.result);
      }
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategorias(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const updateNoticia = async () => {
    if (!noticia.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (noticia.cuerpoNoticia.length == 11) {
      toast.info("Error, tienes que colocar texto a la noticia");
    } /*else if (!noticia.fraseCelebre) {
      toast.info("Error, tienes que colocar una frase celebre");
    } else if (!noticia.autorFraseCelebre) {
      toast.info("Error, tienes que colocar un autor");
    } */ else {
      setLoading(true);
      try {
        const dataNoticia = noticia;
        if (file) {
          await S3Service.delete(noticia.imagen);
          const uploadPhoto = await S3Service.upload(file);
          dataNoticia.imagen = uploadPhoto.result.data;
          const updateNoticia = await NoticiaService.update(dataNoticia);
        } else {
          const updateNoticia = await NoticiaService.update(dataNoticia);
        }
        toast.success("Noticia actualizada con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/blog");
      }
    }
  };

  const myTheme = createTheme({});
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div
            style={{
              boxShadow: "var(--box-shadow)",
              backgroundColor: "var(--color-white)",
            }}
          >
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <h1>Editar Blog</h1>
                <div
                  className={`${styles.recentorders}`}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                    boxShadow: "none",
                    paddingBottom: "0%",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <th>Titulo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  titulo: e.target.value,
                                })
                              }
                              value={noticia.titulo}
                              className={`form-control`}
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                            />
                          </td>
                          <td></td>
                        </tr>
                        <tr></tr>
                        <tr>
                          <th>Descripcion</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginLeft: "6%",
                marginTop: "-5%",
                marginRight: "6%",
              }}
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  onChange={(value) => {
                    console.log(value.getCurrentContent());
                    setNoticia({
                      ...noticia,
                      cuerpoNoticia: stateToHTML(value.getCurrentContent()),
                    });
                  }}
                  defaultValue={contenido}
                />
              </ThemeProvider>
              <br></br>
              <br></br>
            </div>
            {!file && noticia.imagen ? (
              <div className={styles.row}>
                <div className="col-lg-12">
                  <div
                    style={
                      matches
                        ? { textAlign: "center" }
                        : { textAlign: "center", maxWidth: "23rem" }
                    }
                  >
                    <img
                      src={String(imagen)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div style={{ width: "-webkit-fill-available" }}>
              <div style={{ width: "95%", marginLeft: "1.5%" }}>
                <DropZone
                  label="Tamaño recomendado 900px * 600px"
                  footer="Archivos aceptados png,jpeg,jpg"
                  saveFile={async (file) => {
                    setFile(file);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);
                  }}
                />
              </div>
            </div>

            {file ? (
              <div className={styles.row}>
                <div className="col-lg-12">
                  <div
                    style={
                      matches
                        ? { textAlign: "center" }
                        : { textAlign: "center", maxWidth: "23rem" }
                    }
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                    <Typography
                      variant="body1"
                      component="span"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    >
                      {file.name}
                    </Typography>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.fulldiv}>
              <div
                className={styles.recentorders}
                style={{
                  width: "auto",
                  position: "inherit",
                  margin: "inherit",
                  transform: "unset",
                  boxShadow: "none",
                  paddingBottom: "0%",
                }}
              >
                <div style={{ overflowX: "auto" }}>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th>Frase celebre</th>
                        <th>Autor</th>
                      </tr>
                    </thead>
                    <tr />
                    <tbody>
                      <tr>
                        <td>
                          <input
                            onChange={(e) =>
                              setNoticia({
                                ...noticia,
                                fraseCelebre: e.target.value,
                              })
                            }
                            value={noticia.fraseCelebre}
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "90%",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) =>
                              setNoticia({
                                ...noticia,
                                autorFraseCelebre: e.target.value,
                              })
                            }
                            value={noticia.autorFraseCelebre}
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                          />
                        </td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>

                <div style={{ overflowX: "auto" }}>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th>Categoria</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tr />
                    <tbody>
                      <tr>
                        <td>
                          <select
                            className="form-control"
                            id="inlineFormCustomSelect"
                            name="categoria"
                            onChange={(e) => {
                              setNoticia({
                                ...noticia,
                                categoria: e.target.value,
                              });
                            }}
                            defaultValue={noticia.categoria}
                            style={{
                              color: "#7d8da1",
                              marginLeft: "2%",
                              borderWidth: "0px",
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "1.5%",
                              marginLeft: "-1%",
                              width: "45%",
                            }}
                          >
                            {categorias.map((record, index) => {
                              if (record.tipo === noticia.categoria) {
                                return (
                                  <option selected="selected">
                                    {record.tipo}
                                  </option>
                                );
                              } else {
                                return <option>{record.tipo}</option>;
                              }
                            })}
                          </select>
                        </td>
                        <td></td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <br />
            {/* Botones */}
            <div
              className={styles.recentorders}
              style={{
                width: "auto",
                position: "inherit",
                margin: "inherit",
                transform: "unset",
                boxShadow: "none",
                paddingBottom: "0%",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => navigate("/blog")}
                        style={{ margin: "5px" }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => updateNoticia()}
                        style={{ margin: "5px" }}
                      >
                        Guardar
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br />
          <br />
        </>
      )}
    </>
  );
};

export default EditarBlog;
