import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import MensajeService from "../../../services/Mensaje.service";


const BlogMensaje = ({ id,nombre, createdAt, imagenes, MensajeCuerpo, borrar, borrarComentario }) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
  }, []);

  const getImagen = async () => {    
    try {
      if ((imagenes != undefined)&&(imagenes != null)  ){
        console.log();
        const result = await S3Service.get(imagenes);
        setImag(result.result);
      }
    } catch (error) {
      toast.error(error);
    }
  };  

  return (
    <div className="media mb-8">
      <div className="img-overlay rounded mr-4" style={{ width: "12%" }}>
        <img
          className="w-100 lazyestload"
          src={(imagenes==undefined?require("./assets/user.png"):imag)}
          alt="Generic placeholder image"
        />
      </div>
      <div className="media-body">
        <div className="mb-7">
          <h6 className="font-weight-bold text-capitalize mb-2">{nombre}</h6>
          <div className="meta-tag text-gray-color mb-3">
            <i className="fa fa-calendar" aria-hidden="true" />
            <span className="ml-2 text-capitalize">
              {String(createdAt).slice(0, 10)}
            </span>
          </div>
          <p>{MensajeCuerpo}</p>
          {(borrar? <button
                        type="submit"
                        onClick={()=>{borrarComentario(id)}}
                        className="btn text-primary p-0"
                      >
                        Borrar
                      </button>:"")}
        </div>
      </div>
    </div>
  );
};

export default BlogMensaje;
