/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";

/* Servicios */
import IndexService from "../../../services/Index.service";
import styles from "../layout.module.css";

const TablaIndex = () => {
  const contentHTML_content = convertFromHTML("");
  const [index, setIndex] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [mejoresOfertas, setMejoresOfertas] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML_content.contentBlocks,
          contentHTML_content.entityMap
        )
      )
    )
  );
  const [ultimasNoticias, setUltimasNoticias] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML_content.contentBlocks,
          contentHTML_content.entityMap
        )
      )
    )
  );
  const [nuestrosPaquetes, setNuestrosPaquetes] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML_content.contentBlocks,
          contentHTML_content.entityMap
        )
      )
    )
  );
  const [nuestrosHoteles, setNuestroHoteles] = useState(
    JSON.stringify(
      convertToRaw(
        ContentState.createFromBlockArray(
          contentHTML_content.contentBlocks,
          contentHTML_content.entityMap
        )
      )
    )
  );
  const myTheme = createTheme({});

  useEffect(() => {
    getAviso();
  }, []);

  const getAviso = async () => {
    setLoading(true);
    try {
      const idx = await IndexService.get();
      setIndex(idx[0]);
      //Mejores Ofertas
      const contentHTML_contentMejoresOfertas = convertFromHTML(
        idx[0].mejoresOfertas
      );
      const state_contentMejoresOfertas = ContentState.createFromBlockArray(
        contentHTML_contentMejoresOfertas.contentBlocks,
        contentHTML_contentMejoresOfertas.entityMap
      );
      const contentMejoresOfertas = JSON.stringify(
        convertToRaw(state_contentMejoresOfertas)
      );
      setMejoresOfertas(contentMejoresOfertas);
      //Ultimas Noticias
      const contentHTML_ultimasNoticias = convertFromHTML(
        idx[0].ultimasNoticias
      );
      const state_ultimasNoticias = ContentState.createFromBlockArray(
        contentHTML_ultimasNoticias.contentBlocks,
        contentHTML_ultimasNoticias.entityMap
      );
      const contentUltimasNoticias = JSON.stringify(
        convertToRaw(state_ultimasNoticias)
      );
      setUltimasNoticias(contentUltimasNoticias);
      //Nuestros Paquetes
      const contentHTML_nuestrosPaquetes = convertFromHTML(
        idx[0].nuestrosPaquetes
      );
      const state_nuestrosPaquetes = ContentState.createFromBlockArray(
        contentHTML_nuestrosPaquetes.contentBlocks,
        contentHTML_nuestrosPaquetes.entityMap
      );
      const contentNuestrosPaquetes = JSON.stringify(
        convertToRaw(state_nuestrosPaquetes)
      );
      setNuestrosPaquetes(contentNuestrosPaquetes);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAviso = async () => {
    if (!index.mejoresOfertas) {
      toast.info("Error, tienes que agregar texto a mejores ofertas");
    } else if (!index.ultimasNoticias) {
      toast.info("Error, tienes que agregar texto a ultimas noticias");
    } else if (!index.nuestrosPaquetes) {
      toast.info("Error, tienes que agregar texto a nuestros paquetes");
    } else {
      setLoading(true);
      try {
        const dataIndex = index;
        const updateIndex = await IndexService.update(dataIndex);
        toast.success("Index actualizado con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getAviso();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div className="card">
            {/*Mejores ofertas */}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}>
                    {/*Mejores ofertas */}
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <h1
                            className="card-title"
                            style={{ marginRight: "20px", marginTop: "3px" }}
                          >
                            PÁGINA DE INICIO
                          </h1>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th scope="col">
                            <h3
                              className="card-title"
                              style={{ marginTop: "6px" }}
                            >
                              Mejores Ofertas
                            </h3>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginLeft: "6%",
                marginTop: "-5%",
                marginRight: "6%",
                minHeight: "15rem",
              }}
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  onChange={(value) => {
                    console.log(value.getCurrentContent());
                    setIndex({
                      ...index,
                      mejoresOfertas: stateToHTML(value.getCurrentContent()),
                    });
                  }}
                  defaultValue={mejoresOfertas}
                />
              </ThemeProvider>
            </div>

            {/*Ultimas Noticias */}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">
                            <h3
                              className="card-title"
                              style={{ marginTop: "6px" }}
                            >
                              Ultimas Noticias
                            </h3>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginLeft: "6%",
                marginTop: "-5%",
                marginRight: "6%",
                minHeight: "15rem",
              }}
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  onChange={(value) => {
                    console.log(value.getCurrentContent());
                    setIndex({
                      ...index,
                      ultimasNoticias: stateToHTML(value.getCurrentContent()),
                    });
                  }}
                  defaultValue={ultimasNoticias}
                />
              </ThemeProvider>
            </div>

            {/*Nuestros paquetes*/}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}></div>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          <h3
                            className="card-title"
                            style={{ marginTop: "6px" }}
                          >
                            Nuestros Paquetes
                          </h3>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>

            <div
              style={{
                marginLeft: "6%",
                marginTop: "-5%",
                marginRight: "6%",
                minHeight: "15rem",
              }}
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  onChange={(value) => {
                    console.log(value.getCurrentContent());
                    setIndex({
                      ...index,
                      nuestrosPaquetes: stateToHTML(value.getCurrentContent()),
                    });
                  }}
                  defaultValue={nuestrosPaquetes}
                />
              </ThemeProvider>
            </div>

            {/*Nuestros hoteles*/}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}></div>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          <h3
                            className="card-title"
                            style={{ marginTop: "6px" }}
                          >
                            Nuestros Hoteles
                          </h3>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>

            <div
              style={{
                marginLeft: "6%",
                marginTop: "-5%",
                marginRight: "6%",
                minHeight: "15rem",
              }}
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Descripcion nuestros hoteles"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  onChange={(value) => {
                    console.log(value.getCurrentContent());
                    setIndex({
                      ...index,
                      nuestrosHoteles: stateToHTML(value.getCurrentContent()),
                    });
                  }}
                  defaultValue={nuestrosHoteles}
                />
              </ThemeProvider>
            </div>

            {/*Clientes felices*/}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}></div>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          <h3
                            className="card-title"
                            style={{ marginTop: "6px" }}
                          >
                            Clientes felices
                          </h3>
                        </th>
                        <th>
                          <input
                            id="precioPersonasU"
                            placeholder="Precio"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                            value={index.clientesFelices}
                            onChange={(e) => {
                              setIndex({
                                ...index,
                                clientesFelices: e.target.value,
                              });
                            }}
                            type="number"
                          />
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>

            {/*Botones*/}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}></div>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <tbody>
                      <tr>
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center", marginTop: "3%" }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => updateAviso()}
                          >
                            Guardar
                          </Button>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaIndex;
