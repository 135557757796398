import httpClient from "./HttpClient";

const prefix = "/ubicacion";

export default class UbicacionService {
  static async create(ubicacion) {
    return (await httpClient.post(`${prefix}/`, ubicacion)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async update(ubicacion) {
    return (await httpClient.put(`${prefix}/${ubicacion.id}`, ubicacion)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

  static async mostrar(limit) {
    return (await httpClient.get(`${prefix}-mostrar/${limit}`)).data;
  }
}

