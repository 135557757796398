/* React */
import { useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";

/* Components & dependencies */
import { Button, TablePagination } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FullPageLoading from "../../FullPageLoading";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";

/* Service */
import PaqueteService from "../../../services/Paquete.service";

import styles from "../layout.module.css";
import FormDisponible from "./FormDisponible";

const TablaPaquetes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paquetes, setPaquetes] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    loadPaquetes();
  }, [page, limit, update]);

  const loadPaquetes = async () => {
    setLoading(true);
    try {
      const results = await PaqueteService.listPaquetes(limit, page * limit);
      setPaquetes(results.data);
      setTotal(results.total);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onUpdate = () => {
    setUpdate(!update);
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className={styles.recentorders} style={{ display: "grid" }}>
          {/*<div
            style={{
              display: "inline-flex",
              margin: "20px 0",
              justifyContent: "inherit",
            }}
          >
            <h2 style={{ marginRight: "20px" }}>Paquetes</h2>
            <Button
              variant="outlined"
              color="success"
              onClick={() => navigate(`nuevo`)}
            >
              Nuevo
            </Button>
          </div>*/}
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <td>
                    <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                      PAQUETES
                    </h1>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => navigate(`nuevo`)}
                    >
                      Nuevo
                    </Button>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>TITULO</td>
                  <td style={{ fontWeight: "bold" }}>SUBTITULO</td>
                  {/* <td style={{ fontWeight: "bold" }}>DESCRIPCIÓN</td> */}
                  <td style={{ fontWeight: "bold" }}>DISPONIBLE</td>
                  <td style={{ fontWeight: "bold" }}>RESERVAS</td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {paquetes.map((record, index) => {
                  const regex = /<[^>]'>/gim;
                  return (
                    <tr key={`${record.id}_${record.titulo}`}>
                      <td>{record?.titulo.replace(regex, "").slice(0, 30)}</td>
                      <td>
                        {record?.subtitulo.replace(regex, "").slice(0, 30)}...
                      </td>
                      <td>
                        {/* <p
                          dangerouslySetInnerHTML={{
                            __html: String(record.descripcion)
                              .slice(0, 50)
                              .concat("..."),
                          }}
                          style={{
                            textAlign: "justify",
                            fontWeight: "light",
                          }}
                        ></p> */}
                        <FormDisponible paquete={record} onSumit={onUpdate} />
                      </td>
                      <td>
                        <p>
                          {" "}
                          {(record.reservas * 100) / record.cupo}%{" "}
                          <LinearProgress
                            variant="determinate"
                            color="primary"
                            value={(record.reservas * 100) / record.cupo}
                          />{" "}
                        </p>
                      </td>

                      <td style={{ alignItems: "center", textAlign: "center" }}>
                        <span
                          onClick={() => navigate(`editar/${record.id}`)}
                          className="material-icons-sharp"
                        >
                          {" "}
                          edit{" "}
                        </span>
                      </td>
                      <td>
                        <span
                          className="material-icons-sharp"
                          onClick={() => navigate(`eliminar/${record.id}`)}
                        >
                          {" "}
                          delete{" "}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            style={{ color: "var(--color-dark-variant)" }}
            component="div"
            classes="recent-orders"
            labelRowsPerPage="Items por pagina"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default TablaPaquetes;
