import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import PagoService from "../../../services/Pago.service";
import PaqueteService from "../../../services/Paquete.service";
import S3Service from "../../../services/S3.service";
import CancelationDialog from "./CancelationDialog";
import Status from "./Status";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "rgb(110, 204, 21)",
  backgroundColor: "white",
  borderColor: "rgb(110, 204, 21)",
}));
const DashboardUser = () => {
  const [pago, setPago] = useState(null);
  const [paquete, setPaquete] = useState(null);
  const [imagenes, setImagenes] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState({
    error: false,
    consulta: false,
    message: "",
  });
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setError({ ...error, consulta: false });
    if (codigo) {
      consulta();
    }
  }, [update]);

  const consulta = async () => {
    if (!codigo) {
      setError({
        ...error,
        error: true,
        consulta: false,
        message: "No has proporcionado un valor valido",
      });
      return;
    }

    try {
      const pagoResult = await PagoService.getStatus(codigo);
      setPago(pagoResult);
      const result = await PaqueteService.getById(
        parseInt(pagoResult.paquete.id)
      );
      setPaquete(result);
      const imagen = await S3Service.get(result.imagenes[0]);
      setImagenes(imagen.result);
      setError({ ...error, consulta: true });
    } catch (error) {
      setError({
        ...error,
        error: true,
        consulta: false,
        message: error.mensaje,
      });
    }
  };

  const handleCodigo = (event) => {
    if (event.target.value !== "") {
      setError({
        ...error,
        error: false,
        message: "",
      });
    }
    setCodigo(event.target.value);
  };

  const onUpdate = () => {
    setUpdate(!update);
  };

  return (
    <div style={{ with: "100%", minHeight: "99vh", paddingTop: "9rem" }}>
      <div
        style={{
          padding: "0.5rem",
          height: "2rem",
          backgroundColor: "#6ecc15",
          color: "white",
        }}
      ></div>
      <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
        <div style={{ padding: "0.5rem" }}>
          {error.consulta ? (
            <div className="containerPublic">
              <h2 class="text-capitalize mb-5">Detalles de tu paquete</h2>
              <div className="card mb-6 mb-md-0">
                <img
                  className="card-img-top rounded lazyestload"
                  data-src="assets/img/booking/booking-complete.jpg"
                  src={imagenes}
                  alt="Card image cap"
                  style={{ maxHeight: "26rem" }}
                />
                <div className="card-body px-6">
                  <h3 className="text-capitalize mb-5">{paquete?.titulo}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: paquete?.descripcion,
                    }}
                    style={{ textAlign: "justify" }}
                  ></p>
                  <p>
                    La clave de tu reserva es un identificador único que te liga
                    a ella como propietario.
                    <dt>
                      <span>Clave de tu reserva: </span>
                    </dt>
                    {"  "}
                    <span className="text-gray-color">{pago?.paypal_id}</span>
                  </p>
                </div>
                <div className="card-footer bg-transparent">
                  <div className="row">
                    <div className="col-md-4">
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <div className="row">
                            <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                              <dt>
                                <span className="mr-1">
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span>Empieza:</span>
                              </dt>
                            </div>
                            <div className="col-7 col-md-6 pl-0">
                              <span className="text-gray-color">
                                {pago?.paquete.fechaInicial}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-2">
                          <div className="row">
                            <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                              <dt>
                                <span className="mr-1">
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span>Termina:</span>
                              </dt>
                            </div>
                            <div className="col-7 col-md-6 pl-0">
                              <span className="text-gray-color">
                                {pago?.paquete.fechaFinal}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                              <dt>
                                <span className="mr-1">
                                  <i
                                    className="fa fa-user"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span>Huesped:</span>
                              </dt>
                            </div>
                            <div className="col-7 col-md-6 pl-0">
                              <span className="text-gray-color">
                                {pago?.paquete.adulto} Adultos{" "}
                                {pago?.paquete.nino > 0
                                  ? " ," + pago?.paquete.nino + " Niños"
                                  : ""}{" "}
                                {pago?.paquete.extra > 0
                                  ? " ," + pago?.paquete.extra + " Extras"
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <div className="row">
                            <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                              <dt>
                                <span className="mr-1">
                                  <i
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span>Ubicacion:</span>
                              </dt>
                            </div>
                            <div className="col-7 col-md-6 col-lg-7 pl-0 pr-md-0 pr-xl-5">
                              <span className="text-gray-color">
                                {paquete?.ciudad}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <h2 className="text-center text-xl-left mb-0">
                        <span>Total:</span>
                        <span className="text-primary font-weight-bold">
                          {"  "}${pago?.precio}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {" "}
              <img
                data-src="assets/img/blog/tab-01.jpg"
                src="https://images.unsplash.com/photo-1588623570373-24d3f3e3e3fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHVlcnRvJTIwZXNjb25kaWRvfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                alt="Generic placeholder"
                height="100%"
                width="100%"
              />
            </div>
          )}
        </div>
        <div style={{ padding: "0.5rem" }}>
          <div style={{ fontSize: "14px" }}>
            Consultar información con tu clave de compra
          </div>
          <div style={{ paddingTop: "0.6rem", height: "7rem" }}>
            {" "}
            <TextField
              id="codigo"
              type="tet"
              placeholder="Codigo"
              label="Codigo"
              variant="outlined"
              fullWidth
              required
              error={error?.error}
              helperText={error?.message}
              onChange={handleCodigo}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  consulta();
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "0.4rem",
              paddingBottom: "0.4rem",
            }}
          >
            <ColorButton
              variant="outlined"
              onClick={() => {
                consulta();
              }}
            >
              Verificar paquete
            </ColorButton>
          </div>
          <div>
            {error?.consulta ? (
              <ul>
                <li>
                  <Status status={pago?.status} />
                </li>
                {pago?.status !== "Cancelado" ? (
                  <>
                    <li style={{ paddingTop: "1rem" }}>
                      Deseas cancelar el paquete?
                    </li>

                    <li>
                      <CancelationDialog data={pago} onSumit={onUpdate} />
                    </li>
                  </>
                ) : null}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardUser;
