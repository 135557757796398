/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import UserService from "../../../services/User.Service";
import styles from "../layout.module.css";

const TablaUsuarios = () => {
  const [usuarios, setUsuarios] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getUsuarios();
  }, [page, limit]);

  const getUsuarios = async () => {
    setLoading(true);

    try {
      const data = await UserService.list(limit, page * limit);

      setUsuarios(data.data);
      console.log("HOlaaa*****" + usuarios);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.recentorders} style={{ display: "grid" }}>
          {/*<div
            style={{
              display: "inline-flex",
              margin: "20px 0",
              justifyContent: "inherit",
            }}
          >
            <h2 style={{ marginRight: "20px" }}>Usuarios</h2>
            <Button
              variant="outlined"
              color="success"
              onClick={() => navigate(`nuevo`)}
            >
              Nuevo
            </Button>
          </div>*/}
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <td>
                    <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                      USUARIOS
                    </h1>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => navigate(`nuevo`)}
                    >
                      Nuevo
                    </Button>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>NOMBRE</td>
                  <td style={{ fontWeight: "bold" }}>CORREO</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {usuarios.map((record, index) => {
                  if (record.correo == "karimnot@gmail.com") {
                    return (
                      <tr key={`${record.id}`}>
                        <td>{record.nombre}</td>
                        <td>{record.correo}</td>
                        <td> </td>
                        <td></td>
                        <td> </td>
                        <td></td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={`${record.id}`}>
                        <td>{record.nombre}</td>
                        <td>{record.correo}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <span
                            onClick={() => navigate(`editar/${record.id}`)}
                            className="material-icons-sharp"
                          >
                            {" "}
                            edit{" "}
                          </span>
                        </td>

                        <td>
                          {" "}
                          <span
                            className="material-icons-sharp"
                            onClick={() => navigate(`eliminar/${record.id}`)}
                          >
                            {" "}
                            delete{" "}
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            style={{ color: "var(--color-dark-variant)" }}
            component="div"
            classes="recent-orders"
            labelRowsPerPage="Items por página"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default TablaUsuarios;
