import React , { useEffect, Suspense  }  from "react";
import { Outlet } from "react-router-dom";
import FooterPublicLayout from "./FooterPublicLayout";
import HeaderPublicLayout from "./HeaderPublicLayout";
import { CartProvider } from "react-use-cart";

const PublicLayout = () => {
  
  useEffect(() => {
    require("./css/public.css");
  }, []);

  return (
    <CartProvider>
      <body id="body" class="up-scroll">
        <HeaderPublicLayout />
        <Outlet />
        <FooterPublicLayout />
      </body>
    </CartProvider>
  );
};

export default PublicLayout;
