/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";

/*Services */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.service";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "../layout.module.css";

const CrearCarrusel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const [carrusel, setCarrusel] = useState({});
  const matchesmd = useMediaQuery("(min-width:1200px)");
  const matches = useMediaQuery("(min-width:600px)");

  const crearItemCarrusel = async () => {
    setLoading(true);
    try {
      const dataCarrusel = carrusel;
      if (file) {
        const uploadPhoto = await S3Service.upload(file);

        dataCarrusel.imagen = uploadPhoto.result.data;
        await CarruselService.create(dataCarrusel);
        navigate("/carousel");
      } else {
        await CarruselService.create(dataCarrusel);
        navigate("/carousel");
      }
      toast.success("Item de carrusel creado con exito");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className={styles.fulldiv}>
          <div>
            <div className={styles.recentorders}>
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <h1 style={{ marginBottom: "5%" }}>
                        Crear item carrusel
                      </h1>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Titulo</th>
                      <th>Subtitulo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          placeholder="Titulo"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "3%",
                            marginLeft: "-1%",
                            width: "90%",
                          }}
                          value={carrusel?.titulo}
                          onChange={(e) =>
                            setCarrusel({ ...carrusel, titulo: e.target.value })
                          }
                        />
                      </td>
                      <td>
                        <input
                          placeholder="Subtitulo"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "3%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          value={carrusel?.subtitulo}
                          onChange={(e) =>
                            setCarrusel({
                              ...carrusel,
                              subtitulo: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <input
                        placeholder="URL"
                        style={{
                          width: "-webkit-fill-available",
                          backgroundColor: "#dce1eb",
                          padding: "3%",
                          marginLeft: "-1%",
                          width: "90%",
                        }}
                        value={carrusel?.urk}
                        onChange={(e) =>
                          setCarrusel({ ...carrusel, url: e.target.value })
                        }
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              {file ? (
                <>
                  <div
                    style={
                      matches
                        ? { textAlign: "center" }
                        : { textAlign: "center", maxWidth: "23rem" }
                    }
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                    <Typography
                      variant="body1"
                      component="span"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    >
                      {file.name}
                    </Typography>
                  </div>
                </>
              ) : null}
              <div style={matchesmd ? { width: "100%" } : { width: "89%" }}>
                <DropZone
                  label="Tamaño recomendato 1500px * 600px"
                  saveFile={async (file) => {
                    setFile(file);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);
                  }}
                />
              </div>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate("/carousel")}
                      style={{ margin: "5px" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => crearItemCarrusel()}
                      style={{ margin: "5px" }}
                    >
                      Guardar
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CrearCarrusel;
