import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserService from "../../../services/User.Service";
import FullPageLoading from "../../FullPageLoading";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DropZone from "../../utils/DropZoneDos";
import ReCAPTCHA from "react-google-recaptcha";
import S3Service from "../../../services/S3.service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px",
  boxShadow: 24,
  p: 4,
};

const Login = () => {
  const { login } = useContext(AuthContext);
  const [datos, setDatos] = useState({ correo: "", contraseña: "" });
  const [registro, setRegistro] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFile("");
  };
  const [file, setFile] = useState(undefined);
  const [valido, setValido] = useState(true);

  const iniciarSesion = () => {
    if (datos) {
      const data = {
        contraseña: datos.contraseña,
        correo: datos.correo,
      };
      try {
        setLoading(true);
        const promise = UserService.login(data).then((response) => {
          if (response === "Correo o contraseña incorrectas") {
            toast.error("Correo o contraseña incorrectas");
          } else {
            login(response.user, response.token);
            toast.success("Inicio de sesión exitoso");
            // navigate("/");
            window.location.reload();
          }
        });
        toast.promise(promise, {
          pending: "Espere por favor..",
          error: "Revise sus datos de acceso",
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const registrarse = async () => {
    if (!registro.nombre) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (!registro.correo) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } else if (!registro.contraseña) {
      toast.info("Error, tienes que colocar una contraseña");
    } else if (!registro.contraseña2) {
      toast.info("Error, tienes que rellenar todos los campos");
    } else if (registro.contraseña2 != registro.contraseña) {
      toast.info("Error, las contraseñas no coinciden");
    } else {
      setLoading(true);
      try {
        const registroSubir = { ...registro, nombre: registro.nombre };
        registroSubir.tipo = "Usuario";

        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          registroSubir.imagen = uploadPhoto.result.data;
          await UserService.create(registroSubir);
        } else {
          await UserService.create(registroSubir);
        }

        toast.success("Te has registrado con exito!");
        handleClose();
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(false);
  }

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div
            className="main-wrapper blog-grid-three-col"
            style={{ marginBottom: "-6.5%" }}
          >
            <section className="py-9 py-md-10" style={{ marginTop: "3rem" }}>
              <div className="containerPublic">
                <div className="row">
                  <div className="col-lg-7 col-xl-7">
                    <div style={{ marginLeft: "-16%", marginTop: "-1%" }}>
                      <img
                        data-src="assets/img/blog/tab-01.jpg"
                        src="https://images.unsplash.com/photo-1588623570373-24d3f3e3e3fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHVlcnRvJTIwZXNjb25kaWRvfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                        alt="Generic placeholder image"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>

                  <div className="col-lg-5 col-xl-5 order-1">
                    <Grid>
                      <Card
                        style={{
                          maxWidth: 450,
                          padding: "20px 5px",
                          margin: "0 auto",
                        }}
                      >
                        <CardContent>
                          <Typography gutterBottom variant="h5">
                            <h2 className="text-uppercase mb-5">
                              Iniciar sesión
                            </h2>
                          </Typography>

                          <form>
                            <Grid containerPublic spacing={1}>
                              <Grid item xs={12}>
                                <TextField
                                  id="correo"
                                  type="email"
                                  placeholder="Correo electrónico"
                                  label="Correo"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setDatos({
                                      ...datos,
                                      correo: e.target.value,
                                    })
                                  }
                                  onKeyPress={(ev) => {
                                    if (ev.key === "Enter") {
                                      iniciarSesion();
                                    }
                                  }}
                                />
                              </Grid>
                              <br></br>
                              <Grid item xs={12}>
                                <TextField
                                  id="password"
                                  type="password"
                                  placeholder="Contraseña"
                                  label="Contraseña"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setDatos({
                                      ...datos,
                                      contraseña: e.target.value,
                                    })
                                  }
                                  onKeyPress={(ev) => {
                                    if (ev.key === "Enter") {
                                      iniciarSesion();
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <br />
                                <a
                                  href="javascript:void(0);"
                                  className="txt1"
                                  onClick={() => navigate("/forgotpassword")}
                                >
                                  Olvidaste tu contraseña?
                                </a>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  id="botonLogin"
                                  size="large"
                                  variant="contained"
                                  color=""
                                  fullWidth
                                  onClick={() => iniciarSesion()}
                                  style={{
                                    border: "0",
                                    color: "#343a40",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                >
                                  LOGIN
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </CardContent>
                      </Card>
                    </Grid>
                    <br />
                    <Grid>
                      <Card
                        style={{
                          maxWidth: 450,
                          padding: "20px 5px",
                          margin: "0 auto",
                        }}
                      >
                        <CardContent>
                          <Typography gutterBottom variant="h5">
                            <h3 className="text-uppercase mb-5">
                              ¿No cuentas con una cuenta?
                            </h3>
                          </Typography>
                          <form>
                            <Grid containerPublic spacing={1}>
                              <Grid item xs={12}>
                                <Button
                                  size="large"
                                  variant="contained"
                                  color=""
                                  fullWidth
                                  onClick={handleOpen}
                                  style={{
                                    border: "0",
                                    backgroundColor: "#6ecc15",
                                    color: "white",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                >
                                  REGÍSTRATE
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </CardContent>
                      </Card>
                    </Grid>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ position: "absolute" }}
          >
            <Box
              sx={style}
              style={{
                overflow: "auto",
                textOverflow: "ellipsis",
                maxHeight: "650px",
              }}
            >
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Grid>
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ marginBottom: "10%" }}
                  >
                    REGISTRO
                  </Typography>
                  <form>
                    <Grid containerPublic spacing={1}>
                      <Grid item xs={12}>
                        {file ? (
                          <div className="row">
                            <div className="col-lg-12">
                              <div style={{ textAlign: "center" }}>
                                <img
                                  src={URL.createObjectURL(file)}
                                  style={{
                                    height: "100%",
                                    objectFit: "contain",
                                    width: "-webkit-fill-available",
                                    borderRadius: "50%",
                                    padding: "5%",
                                  }}
                                />
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{
                                    width: "-webkit-fill-available",
                                  }}
                                >
                                  {file.name}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <p style={{ width: "100%", textAlign: "center" }}>
                            Subir imagen de perfil
                          </p>
                        )}
                        <div style={{ width: "-webkit-fill-available" }}>
                          <div style={{ width: "100%" }}>
                            <DropZone
                              saveFile={async (file) => {
                                setFile(file);
                                var reader = new FileReader();
                                var url = reader.readAsDataURL(file);
                              }}
                              style={{ backgroundColor: "red" }}
                            />
                          </div>
                        </div>
                      </Grid>
                      <br></br>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          placeholder="Nombre de usuario"
                          label="Nombre de usuario"
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              nombre: e.target.value,
                            })
                          }
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              registrarse();
                            }
                          }}
                        />
                      </Grid>
                      <br></br>
                      <Grid item xs={12}>
                        <TextField
                          type="email"
                          placeholder="Correo electrónico"
                          label="Correo"
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              correo: e.target.value,
                            })
                          }
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              registrarse();
                            }
                          }}
                        />
                      </Grid>
                      <br></br>
                      <Grid item xs={12}>
                        <TextField
                          type="password"
                          placeholder="Contraseña"
                          label="Contraseña"
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              contraseña: e.target.value,
                            })
                          }
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              registrarse();
                            }
                          }}
                        />
                      </Grid>
                      <br></br>
                      <Grid item xs={12}>
                        <TextField
                          type="password"
                          placeholder="Confirmar contraseña"
                          label="Confirmar contraseña"
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              contraseña2: e.target.value,
                            })
                          }
                          onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                              registrarse();
                            }
                          }}
                        />
                      </Grid>
                      <br></br>
                      <Grid item xs={12}>
                        <div
                          className="row"
                          style={{
                            marginLeft: "3%",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={onChange}
                            style={{ width: "150%" }}
                          />
                          <Button
                            size="large"
                            variant="contained"
                            color=""
                            fullWidth
                            disabled={valido}
                            onClick={() => {
                              registrarse();
                            }}
                            style={{ width: "95%" }}
                          >
                            Registrarse
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default Login;
