/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "../layout.module.css";
/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";
const CrearBlog = () => {
  const { currentUser } = useContext(AuthContext);
  const [noticia, setNoticia] = useState({});
  const [categoria, setCategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [etiquetas, setEtiquetas] = useState("");
  const [file, setFile] = useState(undefined);
  const matches = useMediaQuery("(min-width:600px)");

  const myTheme = createTheme({});
  const navigate = useNavigate();

  useEffect(() => {
    getCategoria();
  }, []);

  const saveNoticia = async () => {
    if (!noticia.titulo) {
      toast.info("Error, tienes que agregar un titulo");
    } else if (noticia.cuerpoNoticia.length == 11) {
      toast.info("Error, tienes que colocar texto a la noticia");
    } /*else if (!file) {
      toast.info("Error, tienes que colocar una imagen");
    } else if (!noticia.fraseCelebre) {
      toast.info("Error, tienes que colocar una frase celebre");
    } else if (!noticia.autorFraseCelebre) {
      toast.info("Error, tienes que colocar un autor");
    }  */ else {
      setLoading(true);
      try {
        const noticiaSubir = { ...noticia, usuarioId: currentUser.id };

        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          noticiaSubir.imagen = uploadPhoto.result.data;
          await NoticiaService.create(noticiaSubir);
        } else {
          await NoticiaService.create(noticiaSubir);
        }

        toast.success("Noticia creada con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/blog");
      }
    }
  };

  const getCategoria = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      setCategoria(data);
    } catch (error) {
      toast.error(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div
            style={{
              boxShadow: "var(--box-shadow)",
              backgroundColor: "var(--color-white)",
            }}
          >
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <h1>Crear Blog</h1>
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <th>Titulo</th>
                        </tr>
                      </thead>
                      <tr />
                      <tbody>
                        <tr>
                          <td>
                            <input
                              onChange={(e) =>
                                setNoticia({
                                  ...noticia,
                                  titulo: e.target.value.toUpperCase(),
                                })
                              }
                              value={noticia.titulo}
                              className={`form-control`}
                              placeholder="Titulo"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                            />
                          </td>
                          <td></td>
                        </tr>
                        <tr></tr>
                        <tr>
                          <th>Descripcion</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginLeft: "6%",
                marginTop: "-5%",
                marginRight: "6%",
              }}
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                    // "save"
                  ]}
                  // onSave={save}
                  inlineToolbar={true}
                  onChange={(value) =>
                    setNoticia({
                      ...noticia,
                      cuerpoNoticia: stateToHTML(value.getCurrentContent()),
                    })
                  }
                />
              </ThemeProvider>
              <br></br>
              <br></br>
            </div>
            {file ? (
              <div className={styles.row}>
                <div className="col-lg-12">
                  <div
                    style={
                      matches
                        ? { textAlign: "center" }
                        : { textAlign: "center", maxWidth: "23rem" }
                    }
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                    <Typography
                      variant="body1"
                      component="span"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    >
                      {file.name}
                    </Typography>
                  </div>
                </div>
              </div>
            ) : null}
            <div style={{ width: "-webkit-fill-available" }}>
              <div style={{ width: "95%", marginLeft: "1.5%" }}>
                <DropZone
                  label="Tamaño recomendado 900px * 600px"
                  saveFile={async (file) => {
                    setFile(file);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);
                  }}
                />
              </div>
            </div>
            <div className={styles.fulldiv}>
              <div
                className={styles.recentorders}
                style={{
                  width: "auto",
                  position: "inherit",
                  margin: "inherit",
                  transform: "unset",
                  boxShadow: "none",
                  paddingBottom: "0%",
                }}
              >
                <div style={{ overflowX: "auto" }}>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th>Frase celebre</th>
                        <th>Autor</th>
                      </tr>
                    </thead>
                    <tr />
                    <tbody>
                      <tr>
                        <td>
                          <input
                            onChange={(e) =>
                              setNoticia({
                                ...noticia,
                                fraseCelebre: e.target.value,
                              })
                            }
                            value={noticia.fraseCelebre}
                            placeholder="Frase celebre"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "90%",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) =>
                              setNoticia({
                                ...noticia,
                                autorFraseCelebre: e.target.value,
                              })
                            }
                            value={noticia.autorFraseCelebre}
                            placeholder="Autor"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                          />
                        </td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ overflowX: "auto" }}>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th>Categoria</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tr />
                    <tbody>
                      <tr>
                        <td>
                          <select
                            className={styles.MuiSelectnativeInput}
                            id="inlineFormCustomSelect"
                            name="categoria"
                            onChange={(e) => {
                              setNoticia({
                                ...noticia,
                                categoria: e.target.value,
                              });
                            }}
                            value={noticia.categoria}
                            style={{
                              color: "#7d8da1",
                              marginLeft: "2%",
                              borderWidth: "0px",
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "1.5%",
                              marginLeft: "-1%",
                              width: "45%",
                            }}
                          >
                            <option disabled="disabled" selected="selected">
                              Selecciona una
                            </option>
                            {categoria.map((record, index) => {
                              return <option>{record.tipo}</option>;
                            })}
                          </select>
                        </td>
                        <td></td>
                      </tr>
                      <tr></tr>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />
            {/* Botones */}
            <div
              className={styles.recentorders}
              style={{
                width: "auto",
                position: "inherit",
                margin: "inherit",
                transform: "unset",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => navigate("/blog")}
                        style={{ margin: "5px" }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => saveNoticia()}
                        style={{ margin: "5px" }}
                      >
                        Guardar
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br />
          <br />
        </>
      )}
    </>
  );
};

export default CrearBlog;
