import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import styles from "../layout.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const Slide = () => {
  const { logout } = useContext(AuthContext);
  const [active, setActive] = useState("");
  const matches = useMediaQuery("(min-width:769px)");
  let navigate = useNavigate();

  const closeMenu = () => {
    let element;
    element = document.querySelector("#aside_responsive");
    element.style.display = "none";
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  const navegar = (direccion) => {
    if (direccion === "Dashboard") {
      navigate("/");
      setActive(direccion);
      return;
    }
    navigate(direccion);
    setActive(direccion);
  };

  useEffect(() => {
    let url = window.location.href;
    let urlArray = url.split("/");
    let path = urlArray[urlArray.length - 1].split("#");

    if (path[0] === "") {
      setActive("Dashboard");
    } else {
      setActive(path[0]);
    }
  }, []);
  return (
    <aside
      id="aside_responsive"
      style={
        matches
          ? {
              backgroundColor: "white",
            }
          : {
              backgroundColor: "white",
              paddingInlineStart: "2rem",
              width: "25rem",
              overflow: "auto",
            }
      }
    >
      <div className={styles.top}>
        <div className={styles.logo}>
          {/*<img src="" alt="Logo" />*/}
          <h2>
            CHI<span style={{ color: "#6ecc15" }}>MALLI</span>
          </h2>
          <br />
        </div>
        <div className={styles.close} id="close-btn">
          <span className={"material-icons-sharp"} onClick={() => closeMenu()}>
            {" "}
            close{" "}
          </span>
        </div>
      </div>
      <div className={styles.sidebar} style={{ backgroundColor: "white" }}>
        <a
          href="javascript:void(0);"
          onClick={() => {
            navegar("Dashboard");
          }}
          className={`${active == "Dashboard" && "" + `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> dashboard </span>
          <h3>Dashboard</h3>
        </a>
        <p>
          <h3>Administración</h3>
        </p>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("carousel")}
          className={`${active == "carousel" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> perm_media </span>
          <h3>Carrusel</h3>
        </a>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("blog")}
          className={`${active == "blog" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> menu_book </span>
          <h3>Blog</h3>
        </a>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("categoriaBlog")}
          className={`${active == "categoriaBlog" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> format_list_bulleted </span>
          <h3>Categoria Blog</h3>
        </a>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("paquete")}
          className={`${active == "paquete" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> public </span>
          <h3>Paquetes</h3>
        </a>
        {/* <a
          href="javascript:void(0);"
          onClick={() => navegar("hoteles")}
          className={`${active == "hoteles" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> public </span>
          <h3>Hoteles</h3>
        </a> */}
        {/*<a
          href="javascript:void(0);"
          onClick={() => navegar("places")}
          className={`${active == "places" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> add_location_alt </span>
          <h3>Places</h3>
        </a>*/}
        <a
          href="javascript:void(0);"
          onClick={() => navegar("ubicacion")}
          className={`${active == "ubicacion" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> add_location_alt </span>
          <h3>Ubicacion</h3>
        </a>

        <a
          href="javascript:void(0);"
          onClick={() => navegar("pagos")}
          className={`${active == "pagos" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> event_seat </span>
          <h3>Reservas</h3>
        </a>

        <a
          href="javascript:void(0);"
          onClick={() => navegar("usuario")}
          className={`${active == "usuario" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> group </span>
          <h3>Usuarios</h3>
        </a>

        <p>
          <h3>Edición</h3>
        </p>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("index")}
          className={`${active == "index" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Página de inicio</h3>
        </a>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("aviso")}
          className={`${active == "aviso" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Aviso de privacidad</h3>
        </a>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("terminos")}
          className={`${active == "terminos" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Terminos y condiciones</h3>
        </a>
        <a
          href="javascript:void(0);"
          onClick={() => navegar("footer")}
          className={`${active == "footer" && `${styles.active}`}`}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Footer</h3>
        </a>
        <a href="javascript:void(0);" onClick={() => salir()}>
          <span className="material-icons-sharp"> logout </span>
          <h3>Logout</h3>
        </a>
        <span></span>
      </div>
    </aside>
  );
};
export default Slide;
