import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
const ItemPaquete = ({
  id,
  titulo,
  precioU,
  precioE,
  descuento,
  imagenes,
  descripcion,
  dias,
  tipo,
  item,
}) => {
  const navigate = useNavigate();
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  if (descuento > 0) {
    const descuentoProps = descuento;
    const precioOriginal = precio;
    precioFinal = (precioOriginal * 100) / (precioOriginal / descuentoProps);
  }

  useEffect(() => {
    if (item?.paquetesUsuario) {
      console.log(item.paquetesUsuario);
      for (const pre of item.paquetesUsuario) {
        if (pre.tipo === "Adulto") {
          setPrecio(pre.precio);
        }
      }
    }

    if (item.imagenes?.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(item.imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imag);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const ImpDescuento = () => {
    if (item?.descuento > 0) {
      return (
        <div
          className="badge badge-primary badge-rounded-circle"
          style={{ position: "absolute", top: "-10px", left: "-10px" }}
        >
          <span className="d-block">
            {" "}
            {item?.descuento}%
            <br />
            off{" "}
          </span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <div class="col-6 col-md-4 col-lg-2">
      <div
        class="card card-opacity mb-6 mb-lg-0"
        style={{ overflow: "hidden" }}
      >
        <a
          href="javascript:void(0);"
          class="position-relative"
          onClick={() =>
            item?.nombre
              ? navigate(`/hoteles/${item.id}`)
              : navigate(`/paquete/${item.id}`)
          }
          style={{ height: "20rem", alignSelf: "center" }}
        >
          <img
            class="card-img-top rounded lazyestload"
            data-src="assets/img/home-destination/destination/destination-img-01.jpg"
            src={imag}
            alt="Card image cap"
            style={{ width: "auto", height: "100%" }}
          />
          <div class="card-img-overlay card-hover-overlay d-flex flex-column"></div>
        </a>
        <div class="card-body px-0 pb-0">
          <h5 class="mb-0">
            {item?.nombre ? (
              <a
                href="javascript:void(0);"
                class="card-title font-weight-semibold text-dark text-uppercase"
                style={{ overflowWrap: "anywhere" }}
                onClick={() =>
                  item?.nombre
                    ? navigate(`/hoteles/${item.id}`)
                    : navigate(`/paquete/${item.id}`)
                }
              >
                {item?.nombre}
              </a>
            ) : (
              <a
                href="javascript:void(0);"
                class="card-title font-weight-semibold text-dark text-uppercase"
                onClick={() =>
                  item?.nombre
                    ? navigate(`/hoteles/${item.id}`)
                    : navigate(`/paquete/${item.id}`)
                }
                style={{ overflowWrap: "anywhere" }}
              >
                {item?.titulo}
              </a>
            )}
          </h5>

          {item?.nombre ? (
            <a
              href="javascript:void(0);"
              onClick={() => navigate(`/hoteles/${item.id}`)}
            >
              <p class="mb-0">{item?.ciudad}</p>
            </a>
          ) : (
            <a
              href="javascript:void(0);"
              onClick={() => navigate(`/paquete/${item.id}`)}
            >
              <p class="mb-0">Desde ${precio}</p>
            </a>
          )}
        </div>
        {ImpDescuento()}
      </div>
    </div>
  );
};

export default ItemPaquete;
