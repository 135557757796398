/* React */
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import UserService from "../../../services/User.Service";
import S3Service from "../../../services/S3.service";

import styles from "../layout.module.css";

const NuevoUsuario = () => {
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(undefined);
  const navigate = useNavigate();

  const saveUsuario = async () => {
    if (!usuario.nombre) {
      toast.info("Error, tienes que colocar un nombre");
    } else if (!usuario.correo) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!usuario.contraseña) {
      toast.info("Error, tienes que colocar una contraseña");
    } else if (!usuario.tipo) {
      toast.info("Error, tienes que colocar el tipo de usuario");
      {
        /* else if (!file) {
    toast.info("Error, tienes que colocar una imagen");*/
      }
    } else {
      setLoading(true);
      try {
        const datosUsuario = usuario;
        if (file) {
          const uploadPhoto = await S3Service.upload(file);
          datosUsuario.imagen = uploadPhoto.result.data;
          await UserService.create(datosUsuario);
        } else {
          await UserService.create(datosUsuario);
        }

        toast.success("Usuario creado con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/usuario");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.fulldiv}>
          <div>
            <div className="card">
              <div
                className={styles.recentorders}
                style={{
                  width: "auto",
                  position: "inherit",
                  margin: "inherit",
                  transform: "unset",
                }}
              >
                <div style={{ overflowX: "auto" }}>
                  <table
                    id="recent-orders--table"
                    style={{
                      boxShadow: "initial",
                      textAlign: "justify",
                      width: "79vw",
                    }}
                  >
                    <thead>
                      <tr>
                        <h1 style={{ marginBottom: "5%" }}>Nuevo usuario</h1>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Tipo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "70%" }}>
                          <input
                            placeholder="Nombre"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "2%",
                              marginLeft: "-1%",
                              width: "90%",
                            }}
                            value={usuario?.nombre}
                            onChange={(e) =>
                              setUsuario({ ...usuario, nombre: e.target.value })
                            }
                          />
                        </td>
                        <td style={{ width: "50%" }}>
                          <select
                            className={styles.MuiSelectnativeInput}
                            id="inlineFormCustomSelect"
                            name="tipo"
                            onChange={(e) => {
                              setUsuario({
                                ...usuario,
                                tipo: e.target.value,
                              });
                            }}
                            value={usuario.tipo}
                            style={{
                              color: "#7d8da1",
                              marginLeft: "2%",
                              borderWidth: "0px",
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "4.5%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                          >
                            <option disabled="disabled" selected="selected">
                              Selecciona una
                            </option>
                            <option>Administrador</option>
                            <option>Usuario</option>
                            <option>Empresa</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    id="recent-orders--table"
                    style={{
                      boxShadow: "initial",
                      textAlign: "justify",
                      marginTop: "-4%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Correo</th>
                        <th>Contraseña</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            onChange={(e) =>
                              setUsuario({ ...usuario, correo: e.target.value })
                            }
                            value={usuario.correo}
                            placeholder="Correo"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "90%",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) =>
                              setUsuario({
                                ...usuario,
                                contraseña: e.target.value,
                              })
                            }
                            placeholder="Contraseña"
                            value={usuario.contraseña}
                            type="password"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {file ? (
                  <div className={styles.row}>
                    <div className="col-lg-12">
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={URL.createObjectURL(file)}
                          style={{
                            height: 300,
                            objectFit: "contain",
                            width: "-webkit-fill-available",
                          }}
                        />
                        <Typography
                          variant="body1"
                          component="span"
                          style={{
                            width: "-webkit-fill-available",
                          }}
                        >
                          {file.name}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div style={{ width: "-webkit-fill-available" }}>
                  <DropZone
                    label="Tamaño recomendado 600px * 600px"
                    saveFile={async (file) => {
                      setFile(file);
                      var reader = new FileReader();
                      var url = reader.readAsDataURL(file);
                    }}
                  />
                </div>
                {/* Botones */}
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => navigate("/usuario")}
                        style={{ margin: "5px" }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => saveUsuario()}
                        style={{ margin: "5px" }}
                      >
                        Guardar
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NuevoUsuario;
