/* React */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";

/* Servicios */
import UbicacionService from "../../../services/UbicacionService";

import styles from "../layout.module.css";
import MapLibreUe from "./MapLibreUe";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(34px, 20px) scale(1);",
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
      color: "purple",
    },
    "& .MuiAutocomplete-listbox": {
      "& li:nth-child(even)": { backgroundColor: "red" },
      "& li:nth-child(odd)": { backgroundColor: "black" },
    },
  },
  inputRoot: {
    color: "purple",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
      //backgroundColor:"#dce1eb",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
      //backgroundColor:"#dce1eb",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
      //backgroundColor:"#dce1eb",
    },
  },
}));

const NuevaUbicacion = () => {
  const [ubicacion, setUbicacion] = useState({});
  const [loading, setLoading] = useState(false);
  const [mostrarI, setMostrarI] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setUbicacion({
      ...ubicacion,
      mostrarI: true,
    });
  }, []);

  const navigate = useNavigate();

  const saveUbicacion = async () => {
    if (!ubicacion.lat) {
      toast.info("Error, tienes que colocar una latitud");
    } else if (!ubicacion.lng) {
      toast.info("Error, tienes que colocar una longitud");
    } else if (!ubicacion.ciudad) {
      toast.info("Error, tienes que colocar una ciudad");
    } else if (!ubicacion.pais) {
      toast.info("Error, tienes que colocar un pais");
    } else if (!ubicacion.continente) {
      toast.info("Error, tienes que colocar un continente");
    } else {
      setLoading(true);
      try {
        const datosUbicacion = ubicacion;
        console.log(datosUbicacion);
        await UbicacionService.create(datosUbicacion);
        toast.success("Ubicacion creada con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        navigate("/ubicacion");
      }
    }
  };

  const GradientButton = () => {
    return (
      <button
        style={{
          background:
            "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
          color: "white",
          padding: "16px 32px",
          fontSize: "16px",
          borderRadius: "8px",
        }}
      >
        Botón con degradado de colores
      </button>
    );
  };

  const handleDirection = (data) => {
    setUbicacion(data);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div style={{ boxShadow: "none" }}>
            <div className={styles.recentorders}>
              <div
                style={{ width: "100%", height: "23rem", position: "relative" }}
              >
                <MapLibreUe handleDirection={handleDirection} />
              </div>
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <h1 style={{ marginBottom: "3%" }}>Nueva ubicación</h1>
                      </th>
                    </tr>
                    <tr>
                      Mostrar en busqueda rápida
                      <Switch
                        checked={!mostrarI}
                        onClick={() => {
                          mostrarI ? setMostrarI(false) : setMostrarI(true);
                          console.log("mostrar" + mostrarI);
                        }}
                      />
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Ciudad</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <input
                          id="ciudad"
                          placeholder="Ciudad"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "2%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              ciudad: e.target.value,
                            })
                          }
                          value={ubicacion.ciudad}
                        />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input
                          id="lat"
                          placeholder="Latitud"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "6%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              lat: e.target.value,
                            })
                          }
                          value={ubicacion.lat}
                        />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input
                          id="lng"
                          placeholder="Longitud"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "6%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              lng: e.target.value,
                            })
                          }
                          value={ubicacion.lng}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <th>País</th>
                      <th>Continente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <div>
                          <input
                            id="pais"
                            placeholder="Pais"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                            onChange={(e) =>
                              setUbicacion({
                                ...ubicacion,
                                pais: e.target.value,
                              })
                            }
                            value={ubicacion?.pais}
                          />
                        </div>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div>
                          <input
                            id="continente"
                            placeholder="Continente"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                            onChange={(e) =>
                              setUbicacion({
                                ...ubicacion,
                                continente: e.target.value,
                              })
                            }
                            value={ubicacion?.continente}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/*Boton*/}
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate("/ubicacion")}
                        style={{ margin: "5px" }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => saveUbicacion()}
                        style={{ margin: "5px" }}
                      >
                        Guardar
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NuevaUbicacion;
