import httpClient from "./HttpClient";
const prefix = "";

export default class ForgotPasswordService {
  static async forgotPassword(user) {
    return (await httpClient.post(`${prefix}/forgotPassword`, user)).data;
  }

  static async valideTokenPassword(credentials) {
    return (await httpClient.get(`${prefix}/resetPassword/${credentials.id}/${credentials.token}`)).data;
  }

  static async resetPassword(credentials) {
    return (await httpClient.post(`${prefix}/resetPassword/${credentials.id}/${credentials.token}`,credentials.data)).data;
  }

}
