const ImageLine = ({ imagenes, selectedIndex }) => {
  const imageSelectedStyle = (imageIndex) =>
    imageIndex === selectedIndex
      ? { filter: "brightness(100%)", height: "100%" }
      : { filter: "brightness(50%)", height: "100%" };
  console.log(imagenes);
  return (
    <>
      {imagenes?.map((imagen, index) => (
        <div key={index} style={{ height: "100%" }}>
          <img
            className="d-block w-100"
            src={imagen.result}
            alt={`imagen${index}`}
            style={imageSelectedStyle(index)}
          />
        </div>
      ))}
    </>
  );
};

export default ImageLine;
