/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "../layout.module.css";

const EditarUsuario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [password, setPassword] = useState("");
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UserService.getById(parseInt(id));
      console.log("data");
      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        console.log(imagen);
        setImagen(imagen.result);
      }
      setUsuario(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actualizarUsuario = async () => {
    if (usuario.nombre.length <= 0) {
      toast.info("Error, tienes que colocar un nombre");
    } else if (!usuario.correo) {
      toast.info("Error, tienes que colocar un correo");
    } else {
      setLoading(true);
      try {
        let data = {};
        if (password.length >= 1) {
          data = {
            id: usuario.id,
            nombre: usuario.nombre,
            correo: usuario.correo,
            contraseña: password,
            tipo: usuario.tipo,
          };
          console.log("cambio de contraseña" + data.contraseña);
        } else {
          data = {
            id: usuario.id,
            nombre: usuario.nombre,
            tipo: usuario.tipo,
          };
          console.log("NO cambio de contraseña" + password);
        }

        if (file) {
          await S3Service.delete(usuario.imagen);
          const uploadPhoto = await S3Service.upload(file);
          data.imagen = uploadPhoto.result.data;
        }

        const userActualizado = await UserService.update(data);
        console.log(userActualizado);
        toast.success("Usuario actualizado con exito");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/usuario");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.fulldiv}>
          <div>
            <div
              className={styles.recentorders}
              style={matches ? {} : { marginLeft: "-2.5rem" }}
            >
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <thead>
                  <tr>
                    <h1 style={{ marginBottom: "5%" }}>Editar usuario</h1>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "70%" }}>
                      <input
                        placeholder="Nombre"
                        style={{
                          width: "-webkit-fill-available",
                          backgroundColor: "#dce1eb",
                          padding: "2%",
                          marginLeft: "-1%",
                          width: "90%",
                        }}
                        value={usuario?.nombre}
                        onChange={(e) =>
                          setUsuario({ ...usuario, nombre: e.target.value })
                        }
                      />
                    </td>
                    <td style={{ width: "50%" }}>
                      <select
                        className={styles.MuiSelectnativeInput}
                        id="inlineFormCustomSelect"
                        name="tipo"
                        onChange={(e) => {
                          setUsuario({
                            ...usuario,
                            tipo: e.target.value,
                          });
                        }}
                        value={usuario?.tipo}
                        style={{
                          color: "#7d8da1",
                          marginLeft: "2%",
                          borderWidth: "0px",
                          width: "-webkit-fill-available",
                          backgroundColor: "#dce1eb",
                          padding: "4.5%",
                          marginLeft: "-1%",
                          width: "100%",
                        }}
                      >
                        <option disabled="disabled" selected="selected">
                          Selecciona una
                        </option>
                        <option>Administrador</option>
                        <option>Usuario</option>
                        <option>Empresa</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                id="recent-orders--table"
                style={{
                  boxShadow: "initial",
                  textAlign: "justify",
                  marginTop: "-4%",
                }}
              >
                <thead>
                  <tr>
                    <th>Correo</th>
                    <th>Contraseña</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        onChange={(e) =>
                          setUsuario({ ...usuario, correo: e.target.value })
                        }
                        value={usuario.correo}
                        placeholder="Correo"
                        style={{
                          width: "-webkit-fill-available",
                          backgroundColor: "#dce1eb",
                          padding: "3%",
                          marginLeft: "-1%",
                          width: "90%",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        placeholder="Contraseña"
                        value={password}
                        type="password"
                        style={{
                          width: "-webkit-fill-available",
                          backgroundColor: "#dce1eb",
                          padding: "3%",
                          marginLeft: "-1%",
                          width: "100%",
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {file ? (
                <div className={styles.row}>
                  <div className="col-lg-12">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={URL.createObjectURL(file)}
                        style={{
                          height: 300,
                          objectFit: "contain",
                          width: "-webkit-fill-available",
                        }}
                      />
                      <Typography
                        variant="body1"
                        component="span"
                        style={{
                          width: "-webkit-fill-available",
                        }}
                      >
                        {file.name}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : null}
              {!file && usuario.imagen ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={String(imagen)}
                        style={{
                          height: 300,
                          objectFit: "contain",
                          width: "-webkit-fill-available",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div style={{ width: "-webkit-fill-available" }}>
                <DropZone
                  label="Tamaño recomendado 600px * 600px"
                  saveFile={async (file) => {
                    setFile(file);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);
                  }}
                />
              </div>
              {/* Botones */}
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate("/usuario")}
                      style={{ margin: "5px" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => actualizarUsuario()}
                      style={{ margin: "5px" }}
                    >
                      Guardar
                    </Button>
                  </td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditarUsuario;
