/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import UbicacionService from "../../../services/UbicacionService";

import styles from "../layout.module.css";

const EliminarUbicacion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ubicacion, setUbicacion] = useState({});
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UbicacionService.getById(id);
      setUbicacion(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUbicacion = async () => {
    setLoading(true);
    try {
      const ubicacionBorrada = await UbicacionService.remove(id);
      toast.info("Ubicacion eliminada");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/ubicacion");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.fulldiv}>
          <div>
            <h1>Eliminar ubicación</h1>
            <div className={styles.recentorders}>
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <th>Ciudad</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              ciudad: e.target.value,
                            })
                          }
                          value={ubicacion.ciudad}
                          placeholder="Ciudad"
                          style={{ width: "-webkit-fill-available" }}
                        />
                      </td>
                      <td>
                        <input
                          placeholder="Latitud"
                          style={{ width: "-webkit-fill-available" }}
                          onChange={(e) =>
                            setUbicacion({ ...ubicacion, lat: e.target.value })
                          }
                          value={ubicacion.lat}
                        />
                      </td>
                      <td>
                        <input
                          placeholder="Longitud"
                          style={{ width: "-webkit-fill-available" }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              lng: e.target.value,
                            })
                          }
                          value={ubicacion.lng}
                        />
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>Pais</th>
                      <th>Continente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          placeholder="País"
                          style={{ width: "-webkit-fill-available" }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              pais: e.target.value,
                            })
                          }
                          value={ubicacion.pais}
                        />
                      </td>
                      <td>
                        <select
                          style={{ width: "-webkit-fill-available" }}
                          id="inlineFormCustomSelect"
                          name="continente"
                          onChange={(e) => {
                            setUbicacion({
                              ...ubicacion,
                              continente: e.target.value,
                            });
                          }}
                          value={ubicacion.continente}
                        >
                          <option disabled="disabled" selected>
                            Selecciona uno
                          </option>
                          <option value="America">America</option>
                          <option value="Europa">Europa</option>
                          <option value="Asia">Asia</option>
                          <option value="Africa">África</option>
                          <option value="Oceania">Oceania</option>
                          <option value="Antartida">Antártida</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/*Boton*/}
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tr>
                    <td>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate("/ubicacion")}
                      >
                        Cancelar
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="outlined"
                        color="error"
                        form="form1"
                        onClick={() => deleteUbicacion()}
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminarUbicacion;
