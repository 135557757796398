import React, { useEffect, useState,useContext } from "react";
import CountUp from 'react-countup';

import PaqueteService from '../../../services/Paquete.service'
import UbicacionService from "../../../services/UbicacionService";

const Counter = ({clientesFelices}) => {
  const [paquetes, setPaquetes] = useState(0);
  const [ubicacion,setUbicacion]=useState(0);

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, []);

  const getPaquetes = async () => {
    try {
        const data = await PaqueteService.getAll();
        setPaquetes(data.length);
        const ubicaciones = await UbicacionService.getAll();
        setUbicacion(ubicaciones.length);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  
    return (
      <section className="counter-up dzsparallaxer auto-init use-loading counterup-paralax bg-dark-light ">
      <div
        className="divimage dzsparallaxer--target"
        style={{ height: 110}}
        data-src="assets/img/home/promotion/promotion-2.jpg"
      />
      <div className="containerPublic " style={{display: "block !important"}}>
        <div className="row align-items-center text-center" id="counter">
          <div className="col-6 col-md-3 mb-5 mb-md-0">
            <div className="icon icon-lg rounded-circle mx-auto bg-primary mb-3" >
              <i className="fa fa-map-marker text-white" aria-hidden="true" />
            </div>
            <h1 className="counter-value mb-2">
              <CountUp end={Number(ubicacion)}   duration={0.4} />
            </h1>
            <h6 className="">Destinos</h6>
          </div>
          <div className="col-6 col-md-3 mb-5 mb-md-0">
            <div className="icon icon-lg rounded-circle mx-auto bg-primary mb-3">
              <i className="fa fa-globe text-white" aria-hidden="true" />
            </div>
            <h1 className="counter-value mb-2" data-count={Number(paquetes)}>
              <CountUp end={Number(paquetes)}   duration={0.4} />
            </h1>
            <h6 className="">Paquetes</h6>
          </div>
          <div className="col-6 col-md-3">
            <div className="icon icon-lg rounded-circle mx-auto bg-primary mb-3">
              <i className="fa fa-smile-o text-white" aria-hidden="true" />
            </div>
            <h1 className="counter-value mb-2" data-count={clientesFelices}>
              <CountUp end={clientesFelices}   duration={0.4} />
            </h1>
            <h6 className="">Clientes felices</h6>
          </div>
          <div className="col-6 col-md-3">
            <div className="icon icon-lg rounded-circle mx-auto bg-primary mb-3">
              <i className="fa fa-clock-o text-white" aria-hidden="true" />
            </div>
            <h1 className="counter-value mb-2" data-count={24}>
            <CountUp end={24}   duration={0.4} />
            </h1>
            <h6 className="">Horas de atencion</h6>
          </div>
        </div>
      </div>
    </section>
      
    )
  }

export default Counter;