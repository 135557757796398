const Status = ({ status }) => {
  const styleStatus = {
    Confirmado: {
      color: "#1cd066",
      label: "Confirmado",
    },
    EnProceso: {
      color: "#fec701",
      label: "En proceso",
    },
    Cancelado: {
      color: "#e25a5a",
      label: "Cancelado",
    },
  };
  return (
    <li
      style={{
        padding: "1rem",
        textAlign: "center",
        backgroundColor: `${styleStatus[status].color}`,
        fontWeight: "bold",
        fontSize: "1.2rem",
      }}
    >
      {styleStatus[status].label}
    </li>
  );
};

export default Status;
