import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import PaqueteService from "../../../services/Paquete.service";
import HotelesService from "../../../services/Hoteles.service";

import ItemPaquete from "./ItemPaquete";

const MejoresOfertas = ({ mejoresOfertas }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [tipo, setTipo] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, []);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      let ofertasList = [];
      const paquetesResult = await PaqueteService.mejoresOfertas(4);
      // const hoteles = await HotelesService.mejoresOfertas(3);
      console.log(paquetesResult);
      // ofertasList = [...paquetes.data, ...hoteles.data];

      if (paquetesResult.total < 4) {
        let limite = 4 - paquetesResult.total;
        console.log("----" + limite);
        const dataDESC = await PaqueteService.list(limite, 0);
        ofertasList = [...paquetesResult.data, ...dataDESC.data];
      } else {
        ofertasList = [...paquetesResult.data];
      }

      console.log(ofertasList);
      setPaquetes(ofertasList);
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section class="py-9 py-md-10">
      <div class="container">
        <div class="text-center section-title2">
          <h2 class="text-uppercase font-weight-bold position-relative">
            <span class="bg-white">Mejores ofertas</span>
          </h2>
          <p
            class="text-center"
            dangerouslySetInnerHTML={{
              __html: mejoresOfertas,
            }}
          ></p>
        </div>

        <div class="row justify-content-center" style={{ padding: "1rem" }}>
          {paquetes.map((record, index) => {
            return <ItemPaquete key={index} item={record} tipo={tipo} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default MejoresOfertas;
