import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import FullPageLoading from "../../FullPageLoading";
import { Button, Icon, TablePagination } from "@mui/material";
import styles from "../layout.module.css";
import HabitacionService from "../../../services/Habitacion.service";

const HabitacionesGrid = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [habitaciones, setHabitaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    loadPaquetes();
  }, [page, limit]);

  const loadPaquetes = async () => {
    setLoading(true);
    try {
      const results = await HabitacionService.list({
        limit: limit,
        offset: page * limit,
        hotelId: id,
      });
      setHabitaciones(results.data);
      setTotal(results.total);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className={styles.recentorders} style={{ display: "grid" }}>
          <div style={{ overflow: "auto" }}>
            <table>
              <thead>
                <tr>
                  <td>
                    <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                      Habitaciones
                    </h1>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => navigate(`nuevo/${id}`)}
                    >
                      Nuevo
                    </Button>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <td style={{ fontWeight: "bold" }}>TIPO</td>
                  <td style={{ fontWeight: "bold" }}>CUPO</td>
                  <td style={{ fontWeight: "bold" }}>PRECIO</td>
                  <td style={{ fontWeight: "bold" }}>DISPONIBLE</td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {habitaciones.map((record, index) => {
                  const regex = /<[^>]'>/gim;
                  return (
                    <tr key={`${record.id}_${record.nombre}`}>
                      <td>{record?.tipo.replace(regex, "").slice(0, 30)}</td>
                      <td>{record?.cupo}</td>
                      <td>{record?.precio}</td>
                      <td>
                        <span>ocupación</span>
                        <span
                          onClick={() => navigate(`${record.id}/habitaciones`)}
                        >
                          O
                        </span>
                      </td>
                      {/* <td>
                        <p>
                          {" "}
                          {(record.reservas * 100) / record.cupo}%{" "}
                          <LinearProgress
                            variant="determinate"
                            color="primary"
                            value={(record.reservas * 100) / record.cupo}
                          />{" "}
                        </p>
                      </td> */}

                      <td style={{ alignItems: "center", textAlign: "center" }}>
                        <span
                          onClick={() => navigate(`editar/${record.id}`)}
                          className="material-icons-sharp"
                        >
                          {" "}
                          edit{" "}
                        </span>
                      </td>
                      <td>
                        <span
                          className="material-icons-sharp"
                          onClick={() => navigate(`eliminar/${record.id}`)}
                        >
                          {" "}
                          delete{" "}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            style={{ color: "var(--color-dark-variant)" }}
            component="div"
            labelRowsPerPage="Items por pagina"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default HabitacionesGrid;
