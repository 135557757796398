import { useState, forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@mui/system";
import { Modal, TextField } from "@mui/material";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import IconSelector from "./IconSelector";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
const CrearListaAmenidadModal = ({
  amenidad,
  crearAmenidad,
  updateAmenidad,
}) => {
  const [open, setOpen] = useState(false);
  const [lista, setLista] = useState({
    icon: "",
    label: "",
    options: [
      { icon: "a", text: "text a" },
      { icon: "b", text: "text b" },
    ],
  });
  const [newOption, setNewOption] = useState({ icon: "", text: "" });
  const [error, setError] = useState({
    errorOption: false,
    messageOption: "",
    errorTitle: false,
    messageTitle: "",
  });

  useEffect(() => {
    if (amenidad) {
      setLista(amenidad);
      setOpen(true);
    }
  }, [amenidad]);

  const handleOpen = () => {
    setLista({
      icon: "",
      label: "",
      options: [
        { icon: "a", text: "text a" },
        { icon: "b", text: "text b" },
      ],
    });
    setOpen(true);
  };
  const handleClose = () => {
    updateAmenidad(null);
    setOpen(false);
  };

  const addOption = () => {
    if (!newOption.text) {
      setError({ ...error, errorOption: true, messageOption: "Texto vacío" });
      return;
    }
    let option = newOption;
    setLista({ ...lista, options: [...lista.options, option] });
    setNewOption({ ...newOption, icon: "", text: "" });
  };

  const crearPanel = () => {
    if (!lista.label) {
      setError({ ...error, errorTitle: true, messageTitle: "Titulo vacío" });
      return;
    }

    if (amenidad) {
      updateAmenidad({ ...lista, index: amenidad.index });
    } else {
      crearAmenidad(lista);
    }

    handleClose();
  };

  const handleTitle = (event) => {
    if (event.target.value !== "") {
      setError({
        ...error,
        errorTitle: false,
        messageTitle: "",
      });
    }
    setLista({ ...lista, label: event.target.value });
  };

  const handleOption = (event) => {
    if (event.target.value !== "") {
      setError({
        ...error,
        errorOption: false,
        messageOption: "",
      });

      setNewOption({ ...newOption, text: event.target.value });
    }
  };

  const handleListIcon = (newValue) => {
    if (newValue) {
      setError({
        ...error,
        errorOption: false,
        messageOption: "",
      });

      setLista({ ...lista, icon: newValue });
    }
  };

  const deleteOption = (index) => {
    let oldOptions = lista.options.filter((c, indexc) => indexc !== index);

    setLista({ ...lista, options: oldOptions });
  };

  return (
    <div>
      <ColorButton onClick={handleOpen}>Agrega sección</ColorButton>
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ width: "30rem" }}>
              <div>
                <IconSelector changeIcon={handleListIcon} />{" "}
              </div>
              <TextField
                id="filled-basic"
                label="Texto item"
                variant="filled"
                fullWidth
                error={error.errorOption}
                helperText={error.messageOption}
                onChange={handleOption}
                value={newOption.text}
              />
              <li
                onClick={addOption}
                style={{
                  position: "relative",
                  border: "1px solid lightgray",
                  marginBlock: "2rem",
                }}
              >
                <Button
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    lineHeight: "2.1rem",
                    fontSize: "1rem",
                  }}
                  onClick={addOption}
                >
                  Agregar item
                </Button>
              </li>
              <TextField
                id="filled-basic"
                label="Titulo"
                variant="filled"
                fullWidth
                value={lista.label}
                error={error.errorTitle}
                helperText={error.messageTitle}
                onChange={handleTitle}
              />
              <Button onClick={handleClose}>Cerrar</Button>
              <ColorButton onClick={crearPanel}>Guardar</ColorButton>
            </div>
            <div style={{ minWidth: "20rem", padding: "0.5rem" }}>
              <h3>
                {lista.icon.icon} {lista.label}
              </h3>
              <ul id="transition-modal-description" style={{ marginTop: 16 }}>
                {lista.options.map((option, index) => (
                  <li
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FiberManualRecordIcon sx={{ fontSize: 6 }} />{" "}
                    <span style={{ paddingInline: "0.5rem" }}>
                      {option.text}
                    </span>
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteOption(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </div>
          </Box>
        </Fade>
      </StyledModal>
    </div>
  );
};

export default CrearListaAmenidadModal;

const Backdrop = forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  display: "flex",
  flexWrap: "wrap",
  with: "80%",
  minWidth: "30rem",
  maxWidth: "65rem",
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#e3036d",
  backgroundColor: "white",
  borderColor: "#e3036d",
}));
