/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import useMediaQuery from "@mui/material/useMediaQuery";

/* Servicios */
import CategoriaBlogService from "../../../services/CategoriaBlog.service";

import styles from "../layout.module.css";

const TablaCategoriaBlog = () => {
  const matchesmd = useMediaQuery("(min-width:1200px)");
  const matches = useMediaQuery("(min-width:600px)");
  const [categorias, setCategorias] = useState({});
  const [categoria, setCategoria] = useState({});
  const [categoriaEdit, setCategoriaEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenEliminar = () => {
    setOpenEliminar(true);
  };
  const handleCloseEliminar = () => {
    setOpenEliminar(false);
  };

  useEffect(() => {
    getCategorias();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getCategorias = async () => {
    try {
      const data = await CategoriaBlogService.getAll();
      console.log("get");
      setCategorias(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveCategoria = async () => {
    if (!categoria.tipo) {
      toast.info("Error, tienes que colocar un tipo");
    } else {
      setLoading(true);
      try {
        const datosCategoria = categoria;
        await CategoriaBlogService.create(datosCategoria);
        console.log("save");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getCategorias();
        categoria.tipo = "";
      }
    }
  };

  const updateCategoria = async () => {
    if (!categoriaEdit.tipo) {
      toast.info("Error, tienes que colocar un tipo");
    } else {
      setLoading(true);
      try {
        const datosCategoria = categoriaEdit;
        await CategoriaBlogService.update(datosCategoria);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        handleCloseEdit();
        getCategorias();
      }
    }
  };

  const deleteCategoria = async () => {
    setLoading(true);
    try {
      const carruselBorrado = await CategoriaBlogService.remove(
        categoriaEdit.id
      );
      toast.info("Item de carrusel eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      handleCloseEliminar();
      getCategorias();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className={styles.recentorders}
          style={{
            width: "auto",
            position: "inherit",
            margin: "inherit",
            transform: "unset",
          }}
        >
          <div
            style={{
              margin: "20px 0",
              justifyContent: "inherit",
            }}
          >
            <h2 style={{ marginLeft: "2%" }}>CATEGORIAS BLOG</h2>
          </div>
          <Container
            style={
              matches
                ? {
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                  }
                : {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }
            }
            sx={{ mt: 2, mb: 2 }}
          >
            <Grid
              container
              spacing={1}
              style={
                matches
                  ? {
                      width: "50%",
                      backgroundColor: "red",
                      marginRight: "10%",
                    }
                  : {
                      width: "100%",
                      backgroundColor: "red",
                      marginRight: "0.5rem%",
                    }
              }
            >
              <table style={{ width: "100%", padding: "3%" }}>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Tipo
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {categorias.map((record, index) => {
                    if (record.tipo == "Destacados") {
                      return (
                        <tr key={index}>
                          <th scope="row">{record.id}</th>
                          <td style={{ textAlign: "left" }}>{record.tipo}</td>
                          <td> </td>
                          <td> </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index}>
                          <th scope="row">{record.id}</th>
                          <td style={{ textAlign: "left" }}>{record.tipo}</td>
                          <td>
                            {" "}
                            <span
                              className="material-icons-sharp"
                              onClick={() => {
                                setCategoriaEdit(record);
                                handleOpenEdit();
                              }}
                            >
                              {" "}
                              edit{" "}
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className="material-icons-sharp"
                              onClick={() => {
                                setCategoriaEdit(record);
                                handleOpenEliminar();
                              }}
                            >
                              {" "}
                              delete{" "}
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </Grid>
            <Grid
              container
              spacing={1}
              style={
                matches
                  ? { width: "40%", backgroundColor: "blue" }
                  : { width: "100%", backgroundColor: "blue" }
              }
            >
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th>
                      <p style={{ marginTop: "0%" }}>Agregar etiqueta</p>
                    </th>
                    <th>
                      <input
                        onChange={(e) =>
                          setCategoria({
                            ...categoria,
                            tipo: e.target.value,
                          })
                        }
                        value={categoria.tipo}
                        className={`form-control`}
                        style={{
                          backgroundColor: "#dce1eb",
                          padding: "3%",
                          marginTop: "0%",
                        }}
                      />
                    </th>
                  </tr>

                  <tr>
                    <th></th>
                    <th>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => saveCategoria()}
                        style={{ marginTop: "8%", marginLeft: "55%" }}
                      >
                        Guardar
                      </Button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Container>

          <div
            style={{
              display: "inline-flex",
            }}
          >
            <div></div>

            {/** EDITAR */}
            <Modal
              open={openEdit}
              onClose={handleCloseEdit}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Editar etiqueta
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <table className={style.table}>
                    <tbody>
                      <th>Tipo: {"   "}</th>
                      <th>
                        <input
                          onChange={(e) =>
                            setCategoriaEdit({
                              ...categoriaEdit,
                              tipo: e.target.value,
                            })
                          }
                          value={categoriaEdit.tipo}
                          className={`form-control`}
                        />
                      </th>
                      <th>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => updateCategoria()}
                        >
                          Guardar
                        </Button>
                      </th>
                    </tbody>
                  </table>
                </Typography>
              </Box>
            </Modal>
            {/** ELIMINAR */}
            <Modal
              open={openEliminar}
              onClose={handleCloseEliminar}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Eliminar etiqueta
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <table className={styles.table} width="100%">
                    <tbody>
                      <th>Tipo: {"   "}</th>
                      <th style={{ color: "#ff7782" }}>{categoriaEdit.tipo}</th>
                      <th>
                        <Button
                          variant="outlined"
                          color="error"
                          form="form1"
                          onClick={() => deleteCategoria()}
                        >
                          Eliminar
                        </Button>
                      </th>
                    </tbody>
                  </table>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaCategoriaBlog;
