import React, { Component, useEffect } from "react";
import { useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import SearchOption from "./SearchOption";
import { useNavigate } from "react-router-dom";
import UbicacionService from "../../../services/UbicacionService";

const Search = () => {
  const [Destino, setDestino] = useState("Seleccionar");
  const navigate = useNavigate();
  const [ubicacion, setUbicacion] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getInformacion();
  }, []);

  const getInformacion = async () => {
    let data = await UbicacionService.mostrar(5);
    setUbicacion(data.data);
  };

  return (
    <section className="bg-dark-light py-7">
      <div className="containerPublic">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-2" />
          <div className="col-xl-3">
            <div className="section-title-bg mb-1 mb-lg-3 mb-xl-0">
              <h1 className="text-white text-uppercase mb-0">Busqueda</h1>
              <h2 className="text-white text-uppercase font-weight-bold mb-0">
                rapida
                {/* <span class="text-primary">tours</span> */}
              </h2>
            </div>
          </div>
          <div className="col-xl-4">
            <form className="" action="index.html" method="GET">
              <div className="row">
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-md-6 col-lg-7">
                      <div className="select-dropdown">
                        <div className="form-group mb-lg-0">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="dark"
                              id="dropdown-basic"
                              style={{
                                borderColor: "#212529",
                                backgroundColor: "#212529",
                                color: "whitesmoke",
                                fontSize: "20px",
                                padding: "15px",
                                borderRadius: "0.25rem",
                                width: "300px",
                              }}
                            >
                              {Destino}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              variant="dark"
                              style={{ width: "300px", fontSize: "20px" }}
                            >
                              {ubicacion?.map((record) => {
                                return (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => setDestino(record.ciudad)}
                                    >
                                      {record.ciudad}
                                    </Dropdown.Item>
                                  </>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <a href="javascript:void(0)">
                    <button
                      className="btn btn-primary text-uppercase"
                      type="button"
                      onClick={() => navigate("/paquetes/" + Destino)}
                      style={{
                        fontSize: "20px",
                        padding: "15px",
                        width: "200px",
                      }}
                    >
                      Buscar
                    </button>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Search;
