/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import LinearProgress from "@mui/material/LinearProgress";

/* Servicios */
import S3Service from "../../../services/S3.service";
import PaqueteService from "../../../services/Paquete.service";

import styles from "../layout.module.css";

const EliminarPaquete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [paquete, setPaquete] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    getPago();
  }, []);

  const getPago = async () => {
    setLoading(true);
    try {
      const data = await PaqueteService.getById(id);
      setPaquete(data);
      if (data.imagenes) {
        const result = await S3Service.get(data.imagenes[0]);
        setImagen(result.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePago = async () => {
    setLoading(true);
    try {
      console.log("ELIMINAR");

      const pagoBorrado = await PaqueteService.remove(id);

      toast.info("Paquete eliminado");
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/paquete");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div className={styles.fulldiv}>
            <div style={{ boxShadow: "none" }}>
              <h1>ELIMINAR PAQUETE</h1>
              <div className={styles.recentorders}>
                <div style={{ overflowX: "auto" }}>
                  {paquete.imagenes ? (
                    <div className={styles.row}>
                      <div className="col-lg-12">
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={String(imagen)}
                            style={{
                              height: 300,
                              objectFit: "contain",
                              width: "-webkit-fill-available",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th>Cantidad de reservas</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {paquete.reservas}
                          {"  "}({(paquete.reservas * 100) / paquete.cupo}%)
                          <LinearProgress
                            variant="determinate"
                            color="primary"
                            value={(paquete.reservas * 100) / paquete.cupo}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Paquete</th>
                      </tr>
                      <tr>
                        <td>{paquete.titulo}</td>
                      </tr>
                      <tr>
                        <th>Subtitulo</th>
                      </tr>
                      <tr>
                        <td>{paquete.subtitulo}</td>
                      </tr>
                      <tr>
                        <th>Descripcion</th>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: paquete.descripcion,
                            }}
                            style={{
                              textAlign: "justify",
                              marginRight: "10%",
                            }}
                          ></p>
                        </td>
                      </tr>
                      <tr>
                        <th>Ciudad</th>
                      </tr>
                      <tr>
                        <td>{paquete.ciudad}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className={styles.recentorders}>
            <div style={{ overflowX: "auto" }}>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate("/paquete")}
                      style={{ margin: "5px" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => deletePago()}
                      style={{ margin: "5px" }}
                    >
                      ELIMINAR
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminarPaquete;
