import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Navigate, useNavigate, useParams } from "react-router-dom";

//Services
import PaqueteService from "../../../services/Paquete.service";

//Components
import Titulo from "./Titulo";
import ItemPaquete from "./ItemPaquete";
import TextField from "@mui/material/TextField";
import { TablePagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";

import UbicacionService from "../../../services/UbicacionService";

const PaquetesGrid = () => {
  const { filtroI } = useParams();
  const [loading, setLoading] = useState(true);
  const [paquetes, setPaquetes] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(0);
  const [tipo, setTipo] = useState(0);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [filtro, setFiltro] = useState(false);
  const [filtroCiudades, setFiltroCiudades] = useState([]);
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(undefined);
  const [filtroFechaFin, setFiltroFechaFin] = useState(undefined);
  const [value, setValue] = React.useState([0, 10000]);
  const [names, setNames] = useState([]);

  useEffect(() => {
    if (!filtroI) {
      setPersonName([]);
      getPaquetes();
      setFiltroCiudades([]);
    }
  }, [filtroI]);

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, [page, limit, filtroI]);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }

      let data = await UbicacionService.mostrar(5);
      console.log("----+------subicaciones" + data.data);
      setNames(data.data);

      if (filtroI !== undefined) {
        setFiltro(true);
        // setFiltroCiudades({
        //   ciudades: filtroI,
        // });
        setPersonName((prev) => [...prev, filtroI]);
        setFiltroCiudades((prev) => [...prev, filtroI]);
        console.log("filtro por url: " + filtroI);
        console.log("Filtro inicio");
        console.log(filtroI);
        const data = await PaqueteService.filtroCiudad(100, {
          ciudades: filtroI,
        });
        console.log("tipo:" + data.data);
        setPaquetes(data.data);
      } else {
        const data = await PaqueteService.list(limit, page * limit);
        setPaquetes(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buscar = async () => {
    setLoading(true);

    try {
      if (filtro) {
        console.log(
          "--Hay filtros" +
            filtroCiudades +
            "," +
            filtroFechaInicio +
            "," +
            filtroFechaFin +
            ","
        );
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio == undefined &&
          filtroFechaFin == undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
            };
            console.log("filtro de ciudades");
            console.log("la ciudad:" + filtros.ciudades);
            const data = await PaqueteService.filtroCiudad(100, filtros);
            console.log("--------- en el rango: " + value[0] + "," + value[1]);
            /*if (tipo == "Usuario") {*/
            data.data.forEach((element) => {
              for (const pre of element.paquetesUsuario) {
                if (pre.tipo == "Adulto") {
                  if (pre.precio >= value[0] && pre.precio <= value[1]) {
                    cds.push(element);
                    console.log(
                      "--------- en el rango: " + value[0] + "," + value[1]
                    );
                  }
                }
              }
            });
            /*} else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }*/
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades:" + cds.length);
        }
        if (
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != undefined &&
            filtroFechaFin != undefined) ||
          (filtroCiudades.length == 0 &&
            filtroFechaInicio != "" &&
            filtroFechaFin != "")
        ) {
          const filtros = {
            fechaInicio: filtroFechaInicio.fecha,
            fechaFin: filtroFechaFin.fecha,
          };
          console.log(
            "paquetes del filtro de fechas:" +
              filtros.fechaInicio +
              "--" +
              filtros.fechaFin
          );
          let paq = [];
          const data = await PaqueteService.filtroFecha(100, filtros);
          /*if (tipo == "Usuario") {*/
          data.data.forEach((element) => {
            for (const pre of element.paquetesUsuario) {
              if (pre.tipo == "Adulto") {
                if (pre.precio >= value[0] && pre.precio <= value[1]) {
                  console.log(
                    "--------- en el rango: " + value[0] + "," + value[1]
                  );
                  paq.push(element);
                }
              }
            }
          });
          /*} else {
            data.data.forEach((element) => {
              for (const pre of element.paquetesEmpresa) {
                if (pre.props.tipo == "Adulto") {
                  if (
                    pre.props.precio >= value[0] &&
                    pre.props.precio <= value[1]
                  ) {
                    paq.push(element);
                  }
                }
              }
            });
          }*/
          setPaquetes(paq);
          console.log("paquetes del filtro de fechas:" + paq.length);
        }
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio != undefined &&
          filtroFechaFin != undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
              fechaInicio: filtroFechaInicio.fecha,
              fechaFin: filtroFechaFin.fecha,
            };
            console.log("filtro de ciudades y fechas");
            console.log("la ciudad:" + cd);
            const data = await PaqueteService.filtroCiudadFecha(100, filtros);
            console.log("tipo:" + data.data);
            if (tipo == "Usuario") {
              data.data.forEach((element) => {
                for (const pre of element.paquetesUsuario) {
                  if (pre.tipo == "Adulto") {
                    if (pre.precio >= value[0] && pre.precio <= value[1]) {
                      cds.push(element);
                    }
                  }
                }
              });
            } else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.tipo == "Adulto") {
                    if (pre.precio >= value[0] && pre.precio <= value[1]) {
                      cds.push(element);
                    }
                  }
                }
              });
            }
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades y fecha:" + cds.length);
        }
        if (currentUser != undefined && currentUser.tipo == "Empresa") {
          setTipo("Empresa");
        } else {
          setTipo("Usuario");
        }
      } else {
        console.log("sin filtros");
        let paqs = [];
        const data = await PaqueteService.list(limit, page * limit);
        console.log("----el current data" + data.data.length);
        data.data.forEach((element) => {
          for (const pre of element.paquetesUsuario) {
            if (pre.tipo == "Adulto") {
              if (pre.precio >= value[0] && pre.precio <= value[1]) {
                paqs.push(element);
              }
            }
          }
        });
        setPaquetes(paqs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = useState([]);

  const setFiltrosCiudades = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroCiudades(value);
    setFiltro(true);
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main-wrapper packages-grid">
      <Titulo />

      <section className="bg-smoke py-10">
        <div className="containerPublic">
          <div className="row">
            {/*Izquierda */}
            <div className="col-lg-4 col-xl-3">
              <div className="row">
                {/*#region BUSCAR */}
                <div className="col-md-6 col-lg-12">
                  <div className="mb-6 bg-white p-3 border-top border-top-5 border-primary rounded">
                    <form className="" action="index.html" method="GET">
                      <h4 className="text-uppercase font-weight-bold">
                        BUSCAR
                      </h4>
                      <div className="mb-5">
                        {filtro ? (
                          <p className="text-uppercase font-weight-bold">
                            FILTRO ACTUAL:
                          </p>
                        ) : (
                          ""
                        )}

                        {filtroCiudades.length > 0
                          ? filtroCiudades.map((ciudad) => {
                              return <p>{ciudad}</p>;
                            })
                          : ""}

                        {filtroFechaInicio != undefined ? (
                          <p>Inicio: {filtroFechaInicio.fecha}</p>
                        ) : (
                          ""
                        )}
                        {filtroFechaFin != undefined ? (
                          <p>Fin: {filtroFechaFin.fecha}</p>
                        ) : (
                          ""
                        )}

                        <p className="text-uppercase font-weight-bold">
                          RANGO DE PRECIO:
                        </p>
                        <p>
                          ${value[0]}-${value[1]}
                        </p>
                      </div>
                      <div className="mb-5">
                        <FormControl
                          sx={{
                            m: 1,
                            width: "100%",
                            marginLeft: "-1%",
                            marginTop: "-3%",
                          }}
                        >
                          <InputLabel id="demo-multiple-checkbox-label">
                            Ciudad
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={setFiltrosCiudades}
                            input={<OutlinedInput label="Ciudad" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            sx={{
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6ecc15 !important",
                              },
                            }}
                          >
                            {names.map((name) => (
                              <MenuItem key={name.ciudad} value={name.ciudad}>
                                <Checkbox
                                  checked={personName.indexOf(name.ciudad) > -1}
                                />
                                <ListItemText primary={name.ciudad} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="form-group form-group-icon form-group-icon-dark mb-5">
                        <TextField
                          id="date"
                          label="Fecha de inicio"
                          type="date"
                          InputLabelProps={{
                            style: { color: "#6ecc15" },
                            shrink: true,
                          }}
                          style={{ width: "100%", color: "#6c757d !important" }}
                          sx={{
                            color: "#6c757d !important",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6ecc15 !important",
                            },
                          }}
                          onChange={(e) => {
                            setFiltroFechaInicio(
                              {
                                ...filtroFechaInicio,
                                fecha: e.target.value,
                              },
                              setFiltro(true)
                            );
                          }}
                        />
                      </div>
                      <div className="form-group form-group-icon form-group-icon-dark mb-5">
                        <TextField
                          id="date"
                          label="Fecha fin"
                          type="date"
                          InputLabelProps={{
                            style: { color: "#6ecc15" },
                            shrink: true,
                          }}
                          style={{ width: "100%" }}
                          sx={{
                            color: "#6c757d !important",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6ecc15 !important",
                            },
                          }}
                          onChange={(e) => {
                            setFiltroFechaFin(
                              {
                                ...filtroFechaFin,
                                fecha: e.target.value,
                              },
                              setFiltro(true)
                            );
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          buscar();
                        }}
                        className="btn btn-block btn-xs btn-outline-secondary text-uppercase"
                      >
                        BUSCAR
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (filtroI !== undefined) {
                            navigate("/paquetes");
                          } else {
                            setPersonName([]);
                            getPaquetes();
                            setFiltroCiudades([]);
                          }
                        }}
                        className="btn btn-block btn-xs btn-outline-secondary text-uppercase"
                      >
                        Todo
                      </button>
                    </form>
                  </div>
                </div>
                {/*#endregion */}
                <div className="col-md-6 col-lg-12">
                  <div className="mb-6 bg-white p-3 border-top border-top-5 border-primary rounded overflow-hidden">
                    <h4 className="text-uppercase font-weight-bold">
                      Rango de precios
                    </h4>
                    <div className="price-range mt-5" id="price-range">
                      <div className="price-range-content mb-5">
                        <span className="price-value" id="lower-value">
                          {" "}
                          ${value[0]}
                        </span>
                        <span className="mx-3">
                          <i className="fa fa-minus" aria-hidden="true" />
                        </span>
                        <span className="price-value" id="upper-value">
                          ${value[1]}
                        </span>
                      </div>
                      <Slider
                        getAriaLabel={() => "precio"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        color="primary"
                        max={10000}
                        sx={{ color: "#f0af5b" }}
                      />
                      <div className="mb-6" id="slider-non-linear-step" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Derecha */}
            <div className="col-lg-8 col-xl-9">
              <div className="row">
                {paquetes.length == 0 ? (
                  <div
                    style={{
                      width: "100%",
                      marginTop: "25%",
                      textAlign: "center",
                    }}
                  >
                    <p>LO SENTIMOS PERO NO TENEMOS PAQUETES PARA ESE DESTINO</p>
                  </div>
                ) : (
                  <>
                    {paquetes.map((record, index) => {
                      return (
                        <>
                          <ItemPaquete
                            id={record.id}
                            titulo={record.titulo}
                            precioU={record.paquetesUsuario}
                            precioE={record.paquetesEmpresa}
                            descuento={record.descuento}
                            imagenes={record.imagenes}
                            descripcion={record.descripcion}
                            dias={record.diasJson}
                            tipo={tipo}
                          />
                        </>
                      );
                    })}
                  </>
                )}
              </div>
              {/**Pagination */}
              <>
                {/**Escritorio */}
                {paquetes.length == 0 ? (
                  ""
                ) : (
                  <div className="d-none d-md-block">
                    <div style={{ width: "100%" }}>
                      <div>
                        <TablePagination
                          style={{
                            color: "var(--color-dark-variant)",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                          component="div"
                          classes="recent-orders"
                          labelRowsPerPage="Items por página"
                          //  rowsPerPageOptions={[]}
                          count={total}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={limit}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/**Movil */}
                {paquetes.length == 0 ? (
                  ""
                ) : (
                  <div className="d-md-none">
                    <div className="mb-4">
                      <TablePagination
                        style={{
                          color: "var(--color-dark-variant)",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                        component="div"
                        classes="recent-orders"
                        labelRowsPerPage="Items por página"
                        //  rowsPerPageOptions={[]}
                        count={total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={limit}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaquetesGrid;
