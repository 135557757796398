import { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PagoService from "../../../services/Pago.service";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#e3036d",
  backgroundColor: "white",
  borderColor: "#e3036d",
}));

const ColorTextField = styled(TextField)(({ theme }) => ({
  color: "#e3036d",
  backgroundColor: "white",
  borderColor: "#e3036d",
  "&.MuiFormLabel-root-MuiInputLabel-root": {
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px #e3036d`,
    },
    "&.Mui-focused": {
      boxShadow: `0px 0px 0px 14px #e3036d`,
      color: "#e3036d",
    },
  },
}));

const CancelationDialog = ({ data, onSumit }) => {
  const [open, setOpen] = useState(false);
  const [correo, setCorreo] = useState("");
  const [error, setError] = useState({
    error: false,
    consulta: false,
    message: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCorreo("");
    setOpen(false);
  };

  const cancelConfirm = async () => {
    try {
      if (correo) {
        const result = await PagoService.cancel({
          ...data,
          correoCancel: correo,
        });

        setCorreo("");
        onSumit();
        handleClose();
      } else {
        setError({
          ...error,
          error: true,
          message: "Debes ingresar un correo!",
        });
      }
    } catch (error) {
      setError({
        ...error,
        error: true,
        message: error.mensaje,
      });
    }
  };

  const handleCorreo = (event) => {
    if (event.target.value !== "") {
      setError({
        ...error,
        error: false,
        message: "",
      });
    }
    setCorreo(event.target.value);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "0.4rem",
          paddingBottom: "0.4rem",
        }}
      >
        <ColorButton variant="outlined" onClick={handleClickOpen}>
          Cancelar paquete
        </ColorButton>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cancelar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para cancelar este paquete, por favor ingrese su correo. Nosotros le
            enviaremos la información para continuar con el proceso.
          </DialogContentText>
          <ColorTextField
            autoFocus
            margin="dense"
            error={error.error}
            helperText={error.message}
            id="correo"
            label="Correo"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleCorreo}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={cancelConfirm}>Cancelar paquete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelationDialog;
