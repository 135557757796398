/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { Box, Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import LinearProgress from "@mui/material/LinearProgress";

/* Servicios */
import PagoService from "../../../services/Pago.service";
import PaqueteService from "../../../services/Paquete.service";

const Dashboard = () => {
  const [index, setIndex] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [pagosCancelado, setPagosCancelado] = useState([]);
  const [pagosEnProceso, setPagosEnProceso] = useState([]);
  const [pagosConfirmado, setPagosConfirmado] = useState([]);
  const [paquetes, setPaquetes] = useState([]);

  useEffect(() => {
    getPagos();
  }, []);

  const getPagos = async () => {
    setLoading(true);
    try {
      const dataCancelado = await PagoService.countPagosByStatus("Cancelado");
      setPagosCancelado(dataCancelado.total);
      const dataEnProceso = await PagoService.countPagosByStatus("EnProceso");
      setPagosEnProceso(dataEnProceso.total);
      const dataConfirmado = await PagoService.countPagosByStatus("Confirmado");
      setPagosConfirmado(dataConfirmado.total);
      const reservas = await PaqueteService.reservasAltas(3);
      setPaquetes(reservas.data);
      console.log("----paquetes:" + reservas.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div className="card">
            {/*Mejores ofertas */}
            <div className="fulldiv">
              <div style={{ boxShadow: "none" }}>
                <div className="recent-orders">
                  <div style={{ overflowX: "auto" }}>
                    {/*Mejores ofertas */}
                    <table
                      id="recent-orders--table"
                      style={{
                        boxShadow: "initial",
                        textAlign: "justify",
                        width: "90%",
                      }}
                    >
                      <thead>
                        <tr>
                          <h1
                            className="card-title"
                            style={{
                              marginTop: "2%",
                              marginLeft: "2%",
                              width: "100%",
                            }}
                          >
                            BIENVENIDO
                          </h1>
                        </tr>
                      </thead>
                    </table>
                    <Container style={{ width: "100%" }} sx={{ mt: 2, mb: 2 }}>
                      <Grid container spacing={1}>
                        <Card
                          sx={{
                            minWidth: 300,
                            margin: "10px",
                            marginRight: "30px",
                            display: "flex",
                            backgroundColor: "#ff7782",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "70%",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                Cantidad de reservas
                              </Typography>
                              <Typography
                                variant="h5"
                                component="div"
                                color={"#600911"}
                                style={{ fontWeight: "bold" }}
                              >
                                CANCELADAS
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                (Todos los paquetes)
                              </Typography>
                            </CardContent>
                            <CardActions></CardActions>
                          </Box>
                          <Box
                            style={{
                              textAlign: "center",
                              width: "50%",
                              alignItems: "center",
                              placeContent: "center",
                              backgroundColor: "#bf5962",
                            }}
                          >
                            <Typography
                              style={{
                                textAlign: "center",
                                alignItems: "center",
                                marginTop: "30%",
                                color: "#401e21",
                              }}
                              variant="h2"
                            >
                              <CountUp end={pagosCancelado} duration={0.4} />
                            </Typography>
                          </Box>
                        </Card>
                        <Card
                          sx={{
                            minWidth: 300,
                            margin: "10px",
                            display: "flex",
                            backgroundColor: "#dfa44a",
                            marginRight: "30px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "70%",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                Cantidad de reservas
                              </Typography>
                              <Typography
                                variant="h5"
                                component="div"
                                color={"#705225"}
                                style={{ fontWeight: "bold" }}
                              >
                                EN PROCESO
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                (Todos los paquetes)
                              </Typography>
                            </CardContent>
                            <CardActions></CardActions>
                          </Box>
                          <Box
                            style={{
                              textAlign: "center",
                              width: "50%",
                              alignItems: "center",
                              placeContent: "center",
                              backgroundColor: "#a77b38",
                            }}
                          >
                            <Typography
                              style={{
                                textAlign: "center",
                                alignItems: "center",
                                marginTop: "30%",
                                color: "#543e1c",
                              }}
                              variant="h2"
                            >
                              <CountUp end={pagosEnProceso} duration={0.4} />
                            </Typography>
                          </Box>
                        </Card>
                        <Card
                          sx={{
                            minWidth: 300,
                            margin: "10px",
                            display: "flex",
                            backgroundColor: "#31b589",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "70%",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                Cantidad de reservas
                              </Typography>
                              <Typography
                                variant="h5"
                                component="div"
                                color={"#124433"}
                                style={{ fontWeight: "bold" }}
                              >
                                CONFIRMADAS
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                (Todos los paquetes)
                              </Typography>
                            </CardContent>
                            <CardActions></CardActions>
                          </Box>
                          <Box
                            style={{
                              textAlign: "center",
                              width: "50%",
                              alignItems: "center",
                              placeContent: "center",
                              backgroundColor: "#195b45",
                            }}
                          >
                            <Typography
                              style={{
                                textAlign: "center",
                                alignItems: "center",
                                marginTop: "30%",
                                color: "#0c2d22",
                              }}
                              variant="h2"
                            >
                              <CountUp end={pagosConfirmado} duration={0.4} />
                            </Typography>
                          </Box>
                        </Card>
                      </Grid>
                    </Container>
                    <Container style={{ width: "100%" }}>
                      <p className="card-title" style={{ marginBottom: "2%" }}>
                        RESERVAS MÁS ALTAS
                      </p>
                      {paquetes.map((record, index) => {
                        return (
                          <Card
                            sx={{
                              minWidth: 300,
                              display: "flex",
                              marginBottom: "10px",
                              marginRight: "2%",
                            }}
                          >
                            <CardContent style={{ width: "100%" }}>
                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                {record.titulo}
                                <br></br>
                                {(record.reservas * 100) / record.cupo}%{" "}
                                <br></br>
                                <LinearProgress
                                  variant="determinate"
                                  color="primary"
                                  value={(record.reservas * 100) / record.cupo}
                                />
                              </Typography>
                            </CardContent>
                          </Card>
                        );
                      })}
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
