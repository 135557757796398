/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

import styles from "../layout.module.css";

/* Servicios */
import UbicacionService from "../../../services/UbicacionService";
import MapLibreUe from "./MapLibreUe";

const EditarUbicacion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ubicacion, setUbicacion] = useState({});
  const [loading, setLoading] = useState(true);
  const [mostrarI, setMostrarI] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UbicacionService.getById(id);
      setUbicacion(data);
      setMostrarI(!data.mostrarI);
      console.log(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actualizarUbicacion = async () => {
    if (!ubicacion.lat) {
      toast.info("Error, tienes que colocar una latitud");
    } else if (!ubicacion.lng) {
      toast.info("Error, tienes que colocar una longitud");
    } else if (!ubicacion.ciudad) {
      toast.info("Error, tienes que colocar una ciudad");
    } else if (!ubicacion.pais) {
      toast.info("Error, tienes que colocar un pais");
    } else if (!ubicacion.continente) {
      toast.info("Error, tienes que colocar un continente");
    } else {
      setLoading(true);
      try {
        let data = {};
        data = {
          id: ubicacion.id,
          lat: ubicacion.lat,
          lng: ubicacion.lng,
          ciudad: ubicacion.ciudad,
          pais: ubicacion.pais,
          continente: ubicacion.continente,
          mostrarI: !mostrarI,
        };

        const UbicacionActualizada = await UbicacionService.update(data);
        console.log(UbicacionActualizada);
        toast.success("Ubicacion actualizada con exito");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/ubicacion");
      }
    }
  };

  const handleDirection = (data) => {
    data.id = ubicacion.id;
    setUbicacion(data);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div style={{ boxShadow: "none" }}>
            <div className={styles.recentorders}>
              <div
                style={{ width: "100%", height: "23rem", position: "relative" }}
              >
                <MapLibreUe
                  handleDirection={handleDirection}
                  positionAprox={[Number(ubicacion.lng), Number(ubicacion.lat)]}
                />
              </div>
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <h1 style={{ marginBottom: "3%" }}>Editar ubicación</h1>
                      </th>
                    </tr>
                    <tr>
                      Mostrar en busqueda rápida
                      <Switch
                        checked={!mostrarI}
                        onClick={() => {
                          mostrarI ? setMostrarI(false) : setMostrarI(true);
                          console.log("estado:" + mostrarI);
                        }}
                      />
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Ciudad</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <input
                          id="ciudad"
                          placeholder="Ciudad"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "2%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              ciudad: e.target.value,
                            })
                          }
                          value={ubicacion?.ciudad}
                        />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input
                          id="lat"
                          placeholder="Latitud"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "6%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              lat: e.target.value,
                            })
                          }
                          value={ubicacion?.lat}
                        />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input
                          id="lng"
                          placeholder="Longitud"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "6%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              lng: e.target.value,
                            })
                          }
                          value={ubicacion?.lng}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <th>País</th>
                      <th>Continente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <div>
                          <input
                            id="pais"
                            placeholder="Pais"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                            onChange={(e) =>
                              setUbicacion({
                                ...ubicacion,
                                pais: e.target.value,
                              })
                            }
                            value={ubicacion?.pais}
                          />
                        </div>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div>
                          <input
                            id="continente"
                            placeholder="Continente"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                            onChange={(e) =>
                              setUbicacion({
                                ...ubicacion,
                                continente: e.target.value,
                              })
                            }
                            value={ubicacion?.continente}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/*Boton*/}
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate("/ubicacion")}
                        style={{ margin: "5px" }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => actualizarUbicacion()}
                        style={{ margin: "5px" }}
                      >
                        Guardar
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const continente = [
  { label: "America" },
  {
    label: "Europa",
  },
  { label: "Asia" },
  {
    label: "Africa",
  },
  { label: "Oceania" },
  { label: "Antártida" },
];
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
  { code: "AD", label: "Andorra", number: "376" },
  {
    code: "AE",
    label: "Emiratos Árabes Unidos",
    number: "971",
  },
  { code: "AF", label: "Afganistán", number: "93" },
  {
    code: "AG",
    label: "Antigua y Barbuda",
    number: "1-268",
  },
  { code: "AI", label: "Anguila", number: "1-264" },
  { code: "AL", label: "Albania", number: "355" },
  { code: "AM", label: "Armenia", number: "374" },
  { code: "AO", label: "Angola", number: "244" },
  { code: "AQ", label: "Antártida", number: "672" },
  { code: "AR", label: "Argentina", number: "54" },
  { code: "AS", label: "Samoa Americana", number: "1-684" },
  { code: "AT", label: "Austria", number: "43" },
  {
    code: "AU",
    label: "Australia",
    number: "61",
    sugerido: true,
  },
  { code: "AW", label: "Aruba", number: "297" },
  { code: "AX", label: "Islas Alland", number: "358" },
  { code: "AZ", label: "Azerbaiyán", number: "994" },
  {
    code: "BA",
    label: "Bosnia y Herzegovina",
    number: "387",
  },
  { code: "BB", label: "Barbados", number: "1-246" },
  { code: "BD", label: "Bangladesh", number: "880" },
  { code: "BE", label: "Bélgica", number: "32" },
  { code: "BF", label: "Burkina Faso", number: "226" },
  { code: "BG", label: "Bulgaria", number: "359" },
  { code: "BH", label: "Bahréin", number: "973" },
  { code: "BI", label: "Burundi", number: "257" },
  { code: "BJ", label: "Benín", number: "229" },
  { code: "BL", label: "San Bartolomé", number: "590" },
  { code: "BM", label: "Bermudas", number: "1-441" },
  { code: "BN", label: "Brunei Darussalam", number: "673" },
  { code: "BO", label: "Bolivia", number: "591" },
  { code: "BR", label: "Brasil", number: "55" },
  { code: "BS", label: "Bahamas", number: "1-242" },
  { code: "BT", label: "Bután", number: "975" },
  { code: "BV", label: "Isla Bouvet", number: "47" },
  { code: "BW", label: "Botsuana", number: "267" },
  { code: "POR", label: "Bielorrusia", number: "375" },
  { code: "BZ", label: "Belice", number: "501" },
  {
    code: "CA",
    label: "Canadá",
    number: "1",
    sugerido: true,
  },
  {
    code: "CC",
    label: "Islas Cocos (Keeling)",
    number: "61",
  },
  {
    code: "CD",
    label: "Congo, República Democrática del",
    number: "243",
  },
  {
    code: "CF",
    label: "República Centroafricana",
    number: "236",
  },
  {
    code: "CG",
    label: "Congo, República del",
    number: "242",
  },
  { code: "CH", label: "Suiza", number: "41" },
  { code: "CI", label: "Costa de Marfil", number: "225" },
  { code: "CK", label: "Islas Cook", number: "682" },
  { code: "CL", label: "Chile", number: "56" },
  { code: "CM", label: "Camerún", number: "237" },
  { code: "CN", label: "China", number: "86" },
  { code: "CO", label: "Colombia", number: "57" },
  { code: "CR", label: "Costa Rica", number: "506" },
  { code: "CU", label: "Cuba", number: "53" },
  { code: "CV", label: "Cabo Verde", number: "238" },
  { code: "CW", label: "Curazao", number: "599" },
  { code: "CX", label: "Isla de Navidad", number: "61" },
  { code: "CY", label: "Chipre", number: "357" },
  { code: "CZ", label: "República Checa", number: "420" },
  {
    code: "DE",
    label: "Alemania",
    number: "49",
    sugerido: true,
  },
  { code: "DJ", label: "Yibuti", number: "253" },
  { code: "DK", label: "Dinamarca", number: "45" },
  { code: "DM", label: "Dominica", number: "1-767" },
  {
    code: "HACER",
    label: "República Dominicana",
    number: "1-809",
  },
  { code: "DZ", label: "Argelia", number: "213" },
  { code: "EC", label: "Ecuador", number: "593" },
  { code: "EE", label: "Estonia", number: "372" },
  { code: "EG", label: "Egipto", number: "20" },
  { code: "EH", label: "Sáhara Occidental", number: "212" },
  { code: "ER", label: "Eritrea", number: "291" },
  { code: "ES", label: "España", number: "34" },
  { code: "ET", label: "Etiopía", number: "251" },
  { code: "FI", label: "Finlandia", number: "358" },
  { code: "FJ", label: "Fiyi", number: "679" },
  {
    code: "FK",
    label: "Islas Malvinas (Falkland Islands)",
    number: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Estados Federados de",
    number: "691",
  },
  { code: "FO", label: "Islas Feroe", number: "298" },
  {
    code: "FR",
    label: "Francia",
    number: "33",
    sugerido: true,
  },
  { code: "GA", label: "Gabón", number: "241" },
  { code: "GB", label: "Reino Unido", number: "44" },
  { code: "GD", label: "Granada", number: "1-473" },
  { code: "GE", label: "Georgia", number: "995" },
  { code: "GF", label: "Guayana Francesa", number: "594" },
  { code: "GG", label: "Guernsey", number: "44" },
  { code: "GH", label: "Ghana", number: "233" },
  { code: "GI", label: "Gibraltar", number: "350" },
  { code: "GL", label: "Groenlandia", number: "299" },
  { code: "GM", label: "Gambia", number: "220" },
  { code: "GN", label: "Guinea", number: "224" },
  { code: "GP", label: "Guadalupe", number: "590" },
  { code: "GQ", label: "Guinea Ecuatorial", number: "240" },
  { code: "GR", label: "Grecia", number: "30" },

  {
    code: "GS",
    label: "Islas Georgias del Sur y Sandwich del Sur",
    number: "500",
  },
  { code: "GT", label: "Guatemala", number: "502" },
  { code: "GU", label: "Guam", number: "1-671" },
  { code: "GW", label: "Guinea-Bissau", number: "245" },
  { code: "GY", label: "Guyana", number: "592" },
  { code: "HK", label: "Hong Kong", number: "852" },
  {
    code: "HM",
    label: "Islas Heard y McDonald",
    number: "672",
  },
  { code: "HN", label: "Honduras", number: "504" },
  { code: "HR", label: "Croacia", number: "385" },
  { code: "HT", label: "Haití", number: "509" },
  { code: "HU", label: "Hungría", number: "36" },
  { code: "ID", label: "Indonesia", number: "62" },
  { code: "IE", label: "Irlanda", number: "353" },
  { code: "IL", label: "Israel", number: "972" },
  { code: "IM", label: "Isla de Man", number: "44" },
  { code: "IN", label: "India", number: "91" },
  {
    code: "IO",
    label: "Territorio Británico del Océano Índico",
    number: "246",
  },
  { code: "IQ", label: "Iraq", number: "964" },
  {
    code: "IR",
    label: "Irán, República Islámica de",
    number: "98",
  },
  { code: "IS", label: "Islandia", number: "354" },
  { code: "IT", label: "Italia", number: "39" },
  { code: "JE", label: "Jersey", number: "44" },
  { code: "JM", label: "Jamaica", number: "1-876" },
  { code: "JO", label: "Jordan", number: "962" },
  {
    code: "JP",
    label: "Japón",
    number: "81",
    sugerido: true,
  },
  { code: "KE", label: "Kenia", number: "254" },
  { code: "KG", label: "Kirguistán", number: "996" },
  { code: "KH", label: "Camboya", number: "855" },
  { code: "KI", label: "Kiribati", number: "686" },
  { code: "KM", label: "Comoras", number: "269" },
  {
    code: "KN",
    label: "San Cristóbal y Nieves",
    number: "1-869",
  },
  {
    code: "KP",
    label: "Corea, República Popular Democrática de",
    number: "850",
  },
  { code: "KR", label: "Corea, República de", number: "82" },
  { code: "KW", label: "Kuwait", number: "965" },
  { code: "KY", label: "Islas Caimán", number: "1-345" },
  { code: "KZ", label: "Kazajstán", number: "7" },
  {
    code: "LA",
    label: "República Democrática Popular Lao",
    number: "856",
  },
  { code: "LB", label: "Líbano", number: "961" },
  { code: "LC", label: "Santa Lucía", number: "1-758" },
  { code: "LI", label: "Liechtenstein", number: "423" },
  { code: "LK", label: "Sri Lanka", number: "94" },
  { code: "LR", label: "Liberia", number: "231" },
  { code: "LS", label: "Lesoto", number: "266" },
  { code: "LT", label: "Lituania", number: "370" },
  { code: "LU", label: "Luxemburgo", number: "352" },
  { code: "LV", label: "Letonia", number: "371" },
  { code: "LY", label: "Libia", number: "218" },
  { code: "MA", label: "Marruecos", number: "212" },
  { code: "MC", label: "Mónaco", number: "377" },
  {
    code: "MD",
    label: "Moldavia, República de",
    number: "373",
  },
  { code: "YO", label: "Montenegro", number: "382" },
  {
    code: "MF",
    label: "San Martín (parte francesa)",
    number: "590",
  },
  { code: "MG", label: "Madagascar", number: "261" },
  { code: "MH", label: "Islas Marshall", number: "692" },
  {
    code: "MK",
    label: "Macedonia, la Antigua República Yugoslava de",
    number: "389",
  },
  { code: "ML", label: "Malí", number: "223" },
  { code: "MM", label: "Myanmar", number: "95" },
  { code: "MN", label: "Mongolia", number: "976" },
  { code: "MO", label: "Macao", number: "853" },
  {
    code: "MP",
    label: "Islas Marianas del Norte",
    number: "1-670",
  },
  { code: "MQ", label: "Martinica", number: "596" },
  { code: "MR", label: "Mauritania", number: "222" },
  { code: "MS", label: "Montserrat", number: "1-664" },
  { code: "MT", label: "Malta", number: "356" },
  { code: "MU", label: "Mauricio", number: "230" },
  { code: "MV", label: "Maldivas", number: "960" },
  { code: "MW", label: "Malawi", number: "265" },
  { code: "MX", label: "México", number: "52" },
  { code: "MI", label: "Malasia", number: "60" },
  { code: "MZ", label: "Mozambique", number: "258" },
  { code: "NA", label: "Namibia", number: "264" },
  { code: "NC", label: "Nueva Caledonia", number: "687" },
  { code: "NE", label: "Níger", number: "227" },
  { code: "NF", label: "Isla Norfolk", number: "672" },
  { code: "NG", label: "Nigeria", number: "234" },
  { code: "NI", label: "Nicaragua", number: "505" },
  { code: "NL", label: "Países Bajos", number: "31" },
  { code: "NO", label: "Noruega", number: "47" },
  { code: "NP", label: "Nepal", number: "977" },
  { code: "NR", label: "Nauru", number: "674" },
  { code: "NU", label: "Niue", number: "683" },
  { code: "NZ", label: "Nueva Zelanda", number: "64" },
  { code: "OM", label: "Omán", number: "968" },
  { code: "PA", label: "Panamá", number: "507" },
  { code: "PE", label: "Perú", number: "51" },
  { code: "PF", label: "Polinesia Francesa", number: "689" },
  { code: "PG", label: "Papúa Nueva Guinea", number: "675" },
  { code: "PH", label: "Filipinas", number: "63" },
  { code: "PK", label: "Pakistán", number: "92" },
  { code: "PL", label: "Polonia", number: "48" },
  {
    code: "PM",
    label: "San Pedro y Miquelón",
    number: "508",
  },
  { code: "PN", label: "Pitcairn", number: "870" },
  { code: "PR", label: "Puerto Rico", number: "1" },
  {
    code: "PD",
    label: "Palestina, Estado de",
    number: "970",
  },
  { code: "PT", label: "Portugal", number: "351" },
  { code: "PW", label: "Palau", number: "680" },
  { code: "PY", label: "Paraguay", number: "595" },
  { code: "QA", label: "Qatar", number: "974" },
  { code: "RE", label: "Reunión", number: "262" },
  { code: "RO", label: "Rumania", number: "40" },
  { code: "RS", label: "Serbia", number: "381" },
  { code: "RU", label: "Federación Rusa", number: "7" },
  { code: "RW", label: "Ruanda", number: "250" },
  { code: "SA", label: "Arabia Saudita", number: "966" },
  { code: "SB", label: "Islas Salomón", number: "677" },
  { code: "SC", label: "Seychelles", number: "248" },
  { code: "SD", label: "Sudán", number: "249" },
  { code: "SE", label: "Suecia", number: "46" },
  { code: "SG", label: "Singapur", number: "65" },
  { code: "SH", label: "Santa Elena", number: "290" },
  { code: "SI", label: "Eslovenia", number: "386" },
  {
    code: "SJ",
    label: "Svalbard y Jan Mayen",
    number: "47",
  },
  { code: "SK", label: "Eslovaquia", number: "421" },
  { code: "SL", label: "Sierra Leona", number: "232" },
  { code: "SM", label: "San Marino", number: "378" },
  { code: "SN", label: "Senegal", number: "221" },
  { code: "SO", label: "Somalia", number: "252" },
  { code: "SR", label: "Surinam", number: "597" },
  { code: "SS", label: "Sudán del Sur", number: "211" },
  {
    code: "ST",
    label: "Santo Tomé y Príncipe",
    number: "239",
  },
  { code: "SV", label: "El Salvador", number: "503" },
  {
    code: "SX",
    label: "Sint Maarten (parte holandesa)",
    number: "1-721",
  },
  {
    code: "SY",
    label: "República Árabe Siria",
    number: "963",
  },
  { code: "SZ", label: "Suazilandia", number: "268" },
  {
    code: "TC",
    label: "Islas Turcas y Caicos",
    number: "1-649",
  },
  { code: "TD", label: "Chad", number: "235" },
  {
    code: "TF",
    label: "Territorios Australes Franceses",
    number: "262",
  },
  { code: "TG", label: "Togo", number: "228" },
  { code: "TH", label: "Tailandia", number: "66" },
  { code: "TJ", label: "Tayikistán", number: "992" },
  { code: "TK", label: "Tokelau", number: "690" },
  { code: "TL", label: "Timor-Leste", number: "670" },
  { code: "TM", label: "Turkmenistán", number: "993" },
  { code: "TN", label: "Túnez", number: "216" },
  { code: "TO", label: "Tonga", number: "676" },
  { code: "TR", label: "Turquía", number: "90" },
  {
    code: "TT",
    label: "Trinidad y Tobago",
    number: "1-868",
  },
  { code: "TV", label: "Tuvalu", number: "688" },
  {
    code: "TW",
    label: "Taiwán, República de China",
    number: "886",
  },
  {
    code: "TZ",
    label: "República Unida de Tanzania",
    number: "255",
  },
  { code: "UA", label: "Ucrania", number: "380" },
  { code: "UG", label: "Uganda", number: "256" },
  {
    code: "EE.UU.",
    label: "Estados Unidos",
    number: "1",
    sugerido: true,
  },
  { code: "UY", label: "Uruguay", number: "598" },
  { code: "UZ", label: "Uzbekistán", number: "998" },
  {
    code: "VA",
    label: "Santa Sede (Estado de la Ciudad del Vaticano)",
    number: "379",
  },
  {
    code: "VC",
    label: "San Vicente y las Granadinas",
    number: "1-784",
  },
  { code: "VE", label: "Venezuela", number: "58" },
  {
    code: "VG",
    label: "Islas Vírgenes Británicas",
    number: "1-284",
  },
  {
    code: "VI",
    label: "Islas Vírgenes de los Estados Unidos",
    number: "1-340",
  },
  { code: "VN", label: "Vietnam", number: "84" },
  { code: "VU", label: "Vanuatu", number: "678" },
  { code: "WF", label: "Wallis y Futuna", number: "681" },
  { code: "WS", label: "Samoa", number: "685" },
  { code: "XK", label: "Kosovo", number: "383" },
  { code: "YE", label: "Yemen", number: "967" },
  { code: "YT", label: "Mayotte", number: "262" },
  { code: "ZA", label: "Sudáfrica", number: "27" },
  { code: "ZM", label: "Zambia", number: "260" },
  { code: "ZW", label: "Zimbabwe", number: "263" },
];

export default EditarUbicacion;
