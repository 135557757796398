import React, { useEffect, useState } from "react";
import { useCart } from "react-use-cart";

const PaquetePrecio = ({
  id,
  tipo,
  precio,
  menos,
  mas,
  cant,
  restar,
  sumar,
}) => {
  let cantidad = 0;
  let check = 0;
  if (cant >= 0) {
    cantidad = cant;
  }

  const { addItem, items, removeItem } = useCart();

  const agregarProducto = () => {
    const object = {
      id: idUnico(id + 1),
      idpaquete: id,
      name: tipo,
      price: precio,
      quantity: 1,
    };
    addItem(object);
    console.log("Item agregado:" + items.length);
  };

  const eliminarProducto = () => {
    items.map((record, index) => {
      if (record.name == tipo && check == 0) {
        removeItem(record.id);
        console.log("Item Eliminado:" + record.name);
        check = 1;
        menos(tipo);
        restar(precio);
      }
    });
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  return (
    <div className="form-group mb-5">
      <div className="row align-items-center" style={{ marginLeft: "-40%" }}>
        <label className="control-label col-xl-5 text-center text-xl-right">
          {tipo}
        </label>

        <div className="col-xl-5">
          <div className="count-input mx-auto">
            <button
              className="incr-btn"
              onClick={() => {
                check = 0;
                eliminarProducto();
              }}
            >
              -
            </button>
            <input className="quantity" type="number" value={cantidad} />
            <button
              className="incr-btn"
              onClick={() => {
                mas(tipo);
                sumar(precio);
                agregarProducto();
              }}
            >
              +
            </button>
          </div>
        </div>

        <div className="col-xl-2">
          <p className="text-center mt-3 mt-xl-0 mb-0">${precio}</p>
        </div>
      </div>
    </div>
  );
};

export default PaquetePrecio;
