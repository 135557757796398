import React, { useState, useEffect, useRef } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

const MapLibreUe = ({ handleDirection, positionAprox }) => {
  const geoapify_apiKey = process.env.REACT_APP_GEOAPIFY_KEY;
  const mapContainer = useRef(null);
  const [ubicacion, setUbicacion] = useState(
    positionAprox && positionAprox[0] !== 0
      ? positionAprox
      : [-96.72365, 17.06542]
  );
  const [viewState, setViewState] = useState({
    center: ubicacion,
    zoom: 11,
    // pitch: 20,
  });

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current,
      //   style:
      //     // "https://tiles.basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json",
      //     "https://tiles.basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
      style: {
        ...viewState,
        version: 8,
        name: "Blank",
        // center: [0, 0],
        // zoom: 0,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
            tileSize: 256,
            minzoom: 0,
            maxzoom: 19,
          },
        },
        layers: [
          {
            id: "background",
            type: "background",
            paint: {
              "background-color": "#e0dfdf",
            },
          },
          {
            id: "simple-tiles",
            type: "raster",
            source: "raster-tiles",
          },
        ],
      },
    });

    map.addControl(new maplibregl.ScaleControl(), "bottom-right");
    map.addControl(new maplibregl.FullscreenControl(), "bottom-right");
    map.addControl(new maplibregl.NavigationControl(), "bottom-right");
    map.addControl(new maplibregl.GeolocateControl(), "bottom-right");

    const marker = new maplibregl.Marker().setLngLat(ubicacion).addTo(map);

    map.on("click", async (e) => {
      const { lng, lat } = e.lngLat;

      let requestOptions = {
        method: "GET",
      };

      const results = await fetch(
        `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lng}&apiKey=${geoapify_apiKey}`,
        requestOptions
      ).then((response) => response.json());

      console.log(results.features[0]);
      handleDirection({
        ciudad: `${
          results.features[0].properties.city
            ? `${results.features[0].properties.city}, `
            : ""
        }${
          results.features[0].properties.county
            ? `${results.features[0].properties.county}, `
            : ""
        }${
          results.features[0].properties.state
            ? results.features[0].properties.state
            : ""
        }`,
        pais: results.features[0].properties.country,
        continente: results.features[0].properties.timezone.name.split("/")[0],
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
      // The event object (e) contains information like the
      // coordinates of the point on the map that was clicked.
      marker.setLngLat([e.lngLat.lng, e.lngLat.lat]);
    });

    return () => {
      map.remove();
    };
  }, []);

  return (
    <>
      <div id="results">
        <ul id="search-results"></ul>
      </div>
      <div
        ref={mapContainer}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      ></div>
    </>
  );
};

export default MapLibreUe;
