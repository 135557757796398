import React, { useEffect, useState } from "react";
/*Servicios*/
import S3Service from "../../../services/S3.service";
import CarruselService from "../../../services/Carrusel.service";
/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

const Slide = () => {
  const navigate = useNavigate();
  const [carrusels, setCarrusels] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await CarruselService.getActive();
      setCarrusels(data.data);
      console.log("------------***************" + data.data);
      for (const record of data.data) {
        const imagen = await S3Service.get(record.imagen);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
        console.log("------------" + record.titulo + "*****: " + imagen.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const buton = (url) => {
    /*
    <button type="button" className="btn btn-primary mb-2" >
        IR
      </button>
      */
    return (
      <a
        target="_blank"
        href={url}
        className="rev-btn "
        style={{
          height: "10%",
          width: "10%",
          fontSize: 14,
          fontWeight: 400,
          color: "rgba(255,255,255,1)",
          letterSpacing: 0,
          fontFamily: "montserrat",
          textTransform: "uppercase",
          borderColor: "rgb(255,255,255)",
          borderStyle: "solid",
          borderWidth: "2px 2px 2px 2px",
          outline: "none",
          boxShadow: "none",
          boxSizing: "border-box",
          MozBoxSizing: "border-box",
          WebkitBoxSizing: "border-box",
          padding: "1%",

          cursor: "pointer",
        }}
      >
        IR{" "}
      </a>
    );
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <section>
          <div
            id="rev_slider_12_1"
            className="rev_slider fullwidthabanner"
            style={{
              display: "block",
              backgroundColor: "black",
              height: "600px",
            }}
            data-version="5.4.8.1"
          >
            <Carousel
              slide={false}
              nextLabel={""}
              prevLabel={""}
              style={{ height: "600px" }}
            >
              {carrusels.map((record, index) => {
                return (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={imagenes[index]}
                      alt="imagen"
                      style={{ filter: "brightness(70%)", height: "600px" }}
                    />
                    <Carousel.Caption
                      style={{ alignText: "left", alignItems: "left" }}
                    >
                      <h3>{record.titulo}</h3>
                      <p>{record.subtitulo}</p>
                      {record.url ? buton(record.url) : null}
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </section>
      )}
    </>
  );
};

export default Slide;
