/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";

/* services */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.service";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "../layout.module.css";

const EditarCarrusel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [carrusel, setCarrusel] = useState();
  const matchesmd = useMediaQuery("(min-width:1200px)");
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    loadCarruselItem();
  }, []);

  const loadCarruselItem = async () => {
    setLoading(true);
    try {
      const result = await CarruselService.getById(parseInt(id));
      if (result.imagen) {
        const imagen = await S3Service.get(result.imagen);
        setImagen(imagen.result);
      }
      setCarrusel(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
      return;
    }
  };

  const updateCarrusel = async () => {
    setLoading(true);
    try {
      const dataCarrusel = carrusel;
      if (file) {
        await S3Service.delete(dataCarrusel.imagen);
        const uploadPhoto = await S3Service.upload(file);
        dataCarrusel.imagen = uploadPhoto.result.data;
        await CarruselService.update(dataCarrusel);
      } else {
        await CarruselService.update(dataCarrusel);
      }
      setLoading(false);
      navigate("/carousel");
      toast.success("Item de carrusel actualizado");
    } catch (error) {
      toast.error(error);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className={styles.fulldiv}>
          <div>
            <h1>Editar carrusel</h1>
            <div className={styles.recentorders}>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                  </tr>
                </thead>
                <tr />
                <tbody>
                  <tr>
                    <td>
                      <input
                        placeholder="Titulo"
                        value={carrusel?.titulo}
                        onChange={(e) =>
                          setCarrusel({ ...carrusel, titulo: e.target.value })
                        }
                        style={{
                          width: "-webkit-fill-available",
                          backgroundColor: "#dce1eb",
                          padding: "3%",
                          marginLeft: "-1%",
                          width: "90%",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        placeholder="Subtitulo"
                        value={carrusel?.subtitulo}
                        onChange={(e) =>
                          setCarrusel({
                            ...carrusel,
                            subtitulo: e.target.value,
                          })
                        }
                        style={{
                          width: "-webkit-fill-available",
                          backgroundColor: "#dce1eb",
                          padding: "3%",
                          marginLeft: "-1%",
                          width: "100%",
                        }}
                      />
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
                <thead>
                  <tr>
                    <th>URL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <input
                      placeholder="URL"
                      value={carrusel?.url}
                      onChange={(e) =>
                        setCarrusel({ ...carrusel, url: e.target.value })
                      }
                      style={{
                        width: "-webkit-fill-available",
                        backgroundColor: "#dce1eb",
                        padding: "3%",
                        marginLeft: "-1%",
                        width: "90%",
                      }}
                    />
                  </tr>
                </tbody>
              </table>
              {file ? (
                <>
                  <div
                    style={
                      matches
                        ? { textAlign: "center" }
                        : { textAlign: "center", maxWidth: "23rem" }
                    }
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                    <Typography
                      variant="body1"
                      component="span"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    >
                      {file.name}
                    </Typography>
                  </div>
                </>
              ) : null}
              {!file && carrusel.imagen ? (
                <>
                  <div
                    style={
                      matches
                        ? { textAlign: "center" }
                        : { textAlign: "center", maxWidth: "100%" }
                    }
                  >
                    <img
                      src={String(imagen)}
                      style={
                        matchesmd
                          ? {
                              height: 300,
                              objectFit: "contain",
                              width: "100%",
                            }
                          : matches
                          ? { height: 300, objectFit: "contain", width: "89%" }
                          : {
                              height: 300,
                              objectFit: "contain",
                              width: "100%",
                            }
                      }
                    />
                  </div>
                </>
              ) : null}
              <div style={matchesmd ? { width: "100%" } : { width: "89%" }}>
                <DropZone
                  label="Tamaño recomendado 1500px * 600px"
                  saveFile={async (file) => {
                    setFile(file);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);
                  }}
                />
              </div>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate("/carousel")}
                      style={{ margin: "5px" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => updateCarrusel()}
                      style={{ margin: "5px" }}
                    >
                      Guardar
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditarCarrusel;
