import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FooterService from "../../../services/Footer.service";

const FooterPublicLayout = () => {
  const [footer, setFooter] = useState({ undefined });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const footerText = footer?.Texto?.substring(0, 175) || "";

  useEffect(() => {
    getFooter();
    console.log(footer);
  }, []);

  const getFooter = async () => {
    setLoading(true);
    try {
      const foot = await FooterService.getAll();
      setFooter(foot[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-bg-color py-9">
          <div className="containerPublic">
            <div className="row">
              <div className="col-md-8 col-lg-4 mb-7 mb-lg-0">
                <a
                  className="d-inline-block"
                  href=""
                  onClick={() => navigate("/")}
                >
                  <img
                    className="w-100 lazyestload"
                    data-src="/assets/img/logoPNG.png"
                    src="/assets/img/logoPNG.png"
                    alt="CHIMALLI"
                    style={{ height: "5rem", width: "20px" }}
                  />
                  {/* <h1>CHIMALLI</h1>] */}
                </a>

                <p
                  className="mb-0"
                  style={{ textAlign: "justify", marginRight: "5%" }}
                >
                  {footerText}
                </p>
              </div>
              <div className="col-md-8 col-lg-4 mt-md-4 mb-7 mb-lg-0">
                <div className="title-tag">
                  <h6>Contactanos</h6>
                </div>
                <p style={{ textAlign: "justify", marginRight: "5%" }}>
                  {footer.Contactanos}
                </p>
                <ul className="list-unstyled mb-0">
                  <li className="media mb-2">
                    <div className="mr-3">
                      <i className="fa fa-home" aria-hidden="true" />
                    </div>
                    <div className="media-body">
                      <a>{footer.Direccion}</a>
                    </div>
                  </li>
                  <li className="media mb-2">
                    <div className="mr-3">
                      <i className="fa fa-phone" aria-hidden="true" />
                    </div>
                    <div className="media-body">
                      <a>{footer.Telefono}</a>
                    </div>
                  </li>
                  <li className="media">
                    <div className="mr-3">
                      <i className="fa fa-envelope-o" aria-hidden="true" />
                    </div>
                    <div className="media-body">
                      <a href={"mailTo:" + footer.Correo}>{footer.Correo}</a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-8 col-lg-4 mt-lg-4">
                <div className="title-tag">
                  <h6>Redes sociales</h6>
                </div>
                <p style={{ textAlign: "justify", marginRight: "5%" }}>
                  {footer.RedesSocialesTexto}
                </p>
                <ul className="list-inline d-flex mb-0">
                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <a
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                      href={
                        footer.fb
                          ? "https://" + footer.fb
                          : "https://www.facebook.com"
                      }
                      target="_blank"
                    >
                      <i
                        className="fa fa-facebook text-white"
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <a
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                      href={
                        footer.tw
                          ? "https://" + footer.tw
                          : "https://www.twitter.com"
                      }
                      target="_blank"
                    >
                      <i
                        className="fa fa-twitter text-white"
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <a
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                      href={
                        footer.insta
                          ? "https://" + footer.insta
                          : "https://www.instagram.com"
                      }
                      target="_blank"
                    >
                      <i
                        className="fa fa-instagram text-white"
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <a
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                      href={
                        footer.yt
                          ? "https://" + footer.yt
                          : "https://www.youtube..com"
                      }
                      target="_blank"
                    >
                      <i
                        className="fa fa-youtube-play text-white"
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright py-6">
          <div className="containerPublic">
            <div className="row align-items-center">
              <div
                className="col-md-6 order-1 order-md-0"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <p className="mb-0 mb-md-0 text-md-center">
                  Copyright © 2022{" "}
                  <a href="http://www.karimnot.com/" target="_blank">
                    KARIMNOT INC
                  </a>{" "}
                  | Todos los derechos reservados <br />
                  <a
                    onClick={() => navigate("/aviso")}
                    href="javascript:void(0);"
                  >
                    Aviso de privacidad
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterPublicLayout;
