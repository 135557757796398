/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

/* Servicios */
import UbicacionService from "../../../services/UbicacionService";

import styles from "../layout.module.css";

const TablaUbicacion = () => {
  const [ubicaciones, setUbicaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    getUbicaciones();
  }, [page, limit]);

  const getUbicaciones = async () => {
    setLoading(true);
    try {
      const data = await UbicacionService.list(limit, page * limit);
      if (!data) {
        return;
      }
      setUbicaciones(data.data);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.recentorders} style={{ display: "grid" }}>
          {/*<div
            style={{
              display: "inline-flex",
              margin: "20px 0",
              justifyContent: "inherit",
            }}
          >
            <h2 style={{ marginRight: "20px" }}>Ubicaciones</h2>
            <Button
              variant="outlined"
              color="success"
              onClick={() => navigate(`nuevo`)}
            >              
              Nuevo
            </Button>
          </div>*/}

          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <td>
                    <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                      UBICACIÓN
                    </h1>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => navigate(`nuevo`)}
                    >
                      Nuevo
                    </Button>
                  </td>
                </tr>
              </thead>

              <thead>
                <tr>
                  <td>Ciudad</td>
                  <td>Pais</td>
                  <td>Continente</td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {ubicaciones?.map((record, index) => {
                  return (
                    <tr key={`${record.id}_${record.pais}`}>
                      <td>{record.ciudad}</td>
                      <td>{record.pais}</td>
                      <td>{record.continente}</td>
                      <td>
                        {" "}
                        <a onClick={() => navigate(`editar/${record.id}`)}>
                          <span className="material-icons-sharp"> edit </span>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <span
                          className="material-icons-sharp"
                          onClick={() => navigate(`eliminar/${record.id}`)}
                        >
                          {" "}
                          delete{" "}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TablePagination
            style={{ color: "var(--color-dark-variant)" }}
            component="div"
            classes="recent-orders"
            labelRowsPerPage="Items por página"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default TablaUbicacion;
