/* React */
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
/* Components & dependencies */
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import useMediaQuery from "@mui/material/useMediaQuery";
import FullPageLoading from "../../FullPageLoading";
import styles from "../layout.module.css";
import MapLibreUe from "./MapLibreUe";
import { Button } from "@mui/material";
import CrearListaModal from "./CrearListaModal";
import DropComponent from "../imagesDrop";

/*Services */
import S3Service from "../../../services/S3.service";
import HotelesService from "../../../services/Hoteles.service";

const CrearHotel = () => {
  const matches = useMediaQuery("(min-width:800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hotel, setHotel] = useState({});
  const [mostrarI, setMostrarI] = useState(false);
  const [ubicacion, setUbicacion] = useState(null);
  const [servicios, setServicios] = useState([]);
  const [file, setFile] = useState(undefined);
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);

  useEffect(() => {
    setHotel({
      ...hotel,
      mostrarInicio: false,
    });
  }, []);

  const agregarServico = (newServicio) => {
    setServicios([...servicios, newServicio]);
  };

  const handleDirection = (newDirection) => {
    console.log(newDirection);
    setUbicacion({
      ...ubicacion,
      direccion: newDirection.direccion,
      lat: newDirection.lat,
      long: newDirection.lng,
      ciudad: newDirection.ciudad,
    });
  };

  const handleMostrarI = () => {
    setMostrarI(!mostrarI);
  };

  const guardarHotel = async () => {
    try {
      if (!hotel.nombre) {
        toast.info("Error, tienes que agregar un nombre");
      } else if (!hotel.descripcionCorta) {
        toast.info("Error, tienes que colocar una descripción corta");
      } else if (!hotel.descripcion) {
        toast.info("Error, tienes que colocar una descripción");
      } else if (!ubicacion.direccion) {
        toast.info("Error, tienes que selecionar una ubicación");
        // } else if (!file) {
        //   toast.info("Error, tienes que colocar una imagen para el hotel");
      } else {
        setLoading(true);
        let imagenesArray = []; //Array para los id del s3

        /* Subida de imagenes del carrusel */
        for (const fileCarousel of imagenesCarrusel) {
          const resultFile = await S3Service.upload(fileCarousel);
          console.log("imagen carrusel guardad:" + resultFile.result.data);
          imagenesArray.push(resultFile.result.data);
        }

        const { nombre, descripcionCorta, descripcion } = hotel;

        const dataHotel = {
          nombre,
          descripcionCorta,
          descripcion,
          direccion: ubicacion.direccion,
          lat: ubicacion.lat,
          long: ubicacion.long,
          ciudad: ubicacion.ciudad,
          imagenes: imagenesArray,
          mostrarInicio: mostrarI,
          servicios: servicios,
        };
        const hotelSave = await HotelesService.create(dataHotel);
        console.log("--------------" + hotelSave.response);
        navigate("/hoteles");
        console.log(dataHotel);
        toast.success("Hotel creado con exito " + dataHotel.ciudad);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div
            style={{
              boxShadow: "var(--box-shadow)",
              backgroundColor: "var(--color-white)",
            }}
          >
            <div style={{ width: "-webkit-fill-available", marginTop: "2rem" }}>
              <div
                style={{
                  width: "95%",
                  marginLeft: "1.5%",
                  overflow: "auto",
                }}
              >
                <DropComponent
                  label="Tamaño recomendado 900px * 600px"
                  footer="Archivos aceptados png,jpeg,jpg"
                  subirImagen={(data) => setImagenesCarrusel(data)}
                />
              </div>
            </div>
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div style={{ overflowX: "auto" }}>
                  <table style={{ boxShadow: "initial", textAlign: "justify" }}>
                    <thead>
                      <tr>
                        <th>
                          <h1 style={{ marginBottom: "3%" }}>Crear Hotel</h1>
                        </th>
                      </tr>
                      <tr>
                        Mostrar al inicio
                        <Switch checked={mostrarI} onClick={handleMostrarI} />
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "-webkit-fill-available",
                minHeight: "69vh",
                padding: "1.8rem",
              }}
            >
              <div
                style={
                  matches
                    ? { display: "grid", gridTemplateColumns: "50% 50%" }
                    : { display: "flex", flexDirection: "column" }
                }
              >
                <div>
                  <div style={{ padding: "0.5rem" }}>
                    <TextField
                      id="filled-basic"
                      label="Nombre"
                      variant="filled"
                      fullWidth
                      value={hotel?.nombre}
                      onChange={(event) =>
                        setHotel({
                          ...hotel,
                          nombre: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ padding: "0.5rem" }}>
                    <TextField
                      id="filled-basic"
                      label="Descripción corta"
                      variant="filled"
                      fullWidth
                      value={hotel?.descripcionCorta}
                      onChange={(event) =>
                        setHotel({
                          ...hotel,
                          descripcionCorta: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div style={{ padding: "0.5rem" }}>
                  <TextareaAutosize
                    placeholder="Descripción larga"
                    minRows={8}
                    maxRows={8}
                    style={{
                      backgroundColor: "#f0f0f0",
                      width: "100%",
                      borderBottom: "1px solid #0000006b",
                      fontFamily: "inherit",
                    }}
                    value={hotel?.descripcion}
                    onChange={(event) =>
                      setHotel({
                        ...hotel,
                        descripcion: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  minHeight: "36rem",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "50% 50%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      minHeight: "15rem",
                    }}
                  >
                    <MapLibreUe handleDirection={handleDirection} />
                  </div>
                  <div style={{ padding: "0.5rem" }}>
                    <p>Dirección:</p>
                    {ubicacion?.direccion}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {servicios.map((servicio) => (
                    <div style={{ minWidth: "20rem", padding: "0.5rem" }}>
                      <h3>{servicio.label}</h3>
                      <ul
                        id="transition-modal-description"
                        style={{ marginTop: 16 }}
                      >
                        {servicio.options.map((option) => (
                          <li>
                            {option.icon} - {option.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  <div>
                    <CrearListaModal onSumit={agregarServico} />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => navigate("/hoteles")}
                  style={{ margin: "5px" }}
                >
                  Regresar
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => guardarHotel()}
                  style={{ margin: "5px" }}
                >
                  GUARDAR HOTEL
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CrearHotel;
