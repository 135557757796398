import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";

/*Servicios*/
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";

const ItemNoticia = ({ data }) => {
  const navegar = useNavigate();
  const [usuario, setUsuario] = useState("");
  const [imagen, setImagen] = useState({});
  const regex = /<[^>]'>/gim;

  useEffect(() => {
    window.scrollTo(0, 0);
    getImage();
  }, []);

  const getImage = async () => {
    try {
      S3Service.get(data.imagen).then((dataimagen) => {
        document.getElementById(`imagen${data.imagen}`).src = dataimagen.result;
        setImagen(dataimagen.result);
      });
      const aut = await UserService.getById(data.usuarioId);
      setUsuario(aut.nombre);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="col-lg-6 mb-6">
        <div className="media media-img-wrapper flex-column flex-md-row">
          <div className="img-overlay media-img">
            <img
              className="lazyestload"
              data-src="assets/img/home-city/travel-tips-img-1.jpg"
              alt="Generic placeholder image"
              id={`imagen${data.imagen}`}
              style={{ height: "350px" }}
            />
            <a
              href="javascript:void(0)"
              className="hover-img-overlay-dark"
              onClick={() => navegar(`/blog/${data.id}`)}
            />
          </div>
          <div className="media-body">
            <h6 className="text-uppercase mb-2">
              <a
                href="javascript:void(0)"
                onClick={() => navegar(`/blog/${data.id}`)}
                style={{ overflowWrap: "anywhere" }}
              >
                {data.titulo}
              </a>
            </h6>
            <ul className="list-unstyled d-flex mb-4">
              {/* <li>
                <a
                  className="mr-4"
                  href="javascript:void(0)"
                  onClick={() => navegar(`/blog/${data.id}`)}
                >
                  <i className="fa fa-user-o text-primary" aria-hidden="true" />
                  <span className="ml-1">por {aut.nombre}</span>
                </a>
              </li> */}
              <li>
                <a
                  className="mr-4"
                  href="javascript:void(0)"
                  onClick={() => navegar(`/blog/${data.id}`)}
                >
                  <i
                    className="fa fa-calendar-o text-primary"
                    aria-hidden="true"
                  />
                  <span className="ml-1">
                    {String(data.updateAt).slice(0, 10)}
                  </span>
                </a>
              </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: String(data.cuerpoNoticia)
                    .slice(0, 253)
                    .concat("..."),
                }}
                style={{
                  textAlign: "justify",
                  marginRight: "10%",
                }}
              ></p>
            </p>
            <a
              href="javascript:void(0)"
              onClick={() => navegar(`/blog/${data.id}`)}
              className="text-uppercase"
            >
              leer más
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemNoticia;
