import { useState, forwardRef, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PropTypes from "prop-types";
import { Box, styled } from "@mui/system";
import { Modal, TextField } from "@mui/material";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";
import FullPageLoading from "../../FullPageLoading";
import S3Service from "../../../services/S3.service";
import useMediaQuery from "@mui/material/useMediaQuery";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";

const HabitacionModal = ({ habitacion, onSumit, fechas, hotelId }) => {
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(min-width:800px)");
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [fecha, setFecha] = useState();
  const { currentUser } = useContext(AuthContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChangeCarouselIndex = (newIndex) => {
    setCarouselIndex(newIndex);
  };

  let dias = 0;
  let cargaPrecio = 0;
  var today = new Date();
  let check = 0;
  let adultoPrecio = 0;
  let adultoQuantity = 0;

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  useEffect(() => {
    setImagenes([]);
    getImagenes();
    vaciarCart();
  }, [open]);

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const getImagenes = async () => {
    setLoading(true);
    try {
      for (const url of habitacion.imagenes) {
        const imagen = await S3Service.get(url);
        setImagenes((imagenes) => [...imagenes, imagen]);
      }

      console.log(imagenes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const reservar = () => {
    if (currentUser == undefined) {
      toast.error("Por favor inicia sesión para comprar");
    } else {
      let fechaFinal = new Date(fecha);
      //let fechaFinal=f.getDate()+paquete.dias.length();
      fechaFinal.setDate(fechaFinal.getDate() + dias);

      const object = {
        id: idUnico(habitacion.id + 1),
        hotelId: hotelId,
        habitacionId: habitacion.id,
        tipo: habitacion.tipo,
        price: habitacion.precio,
        cupo: habitacion.cupo,
        fechaInicial: fechas.inicio,
        fechaFinal: fechas.final,
      };
      addItem(object);
      console.log("Item agregado Adulto:" + object.name);
      navigate(`/bookingHotelStepUno/${habitacion.id}`);
    }
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div>
          <ColorButton onClick={handleOpen}>Detalles</ColorButton>
          <StyledModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: StyledBackdrop }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div
                  className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden"
                  style={{ width: "100%" }}
                >
                  <div className="swiper-wrapper h-100">
                    <Carousel
                      slide={false}
                      nextLabel={""}
                      prevLabel={""}
                      onSelect={handleChangeCarouselIndex}
                      indicators={false}
                      data-bs-theme="dark"
                      style={{ marginTop: "0.5rem" }}
                    >
                      {imagenes?.map((record, index) => {
                        return (
                          <Carousel.Item key={index}>
                            <div
                              style={{
                                height: "44.5rem",
                                width: "100%",
                                backgroundImage: `url(${imagenes[index].result})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            >
                              {/* <img
                            className="d-block h-100"
                            src={imagenes[index].result}
                            alt="imagen"
                          /> */}
                            </div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                  {/* Add Arrows */}
                </div>

                <div
                  style={{
                    boxShadow: "var(--box-shadow)",
                    backgroundColor: "var(--color-white)",
                  }}
                >
                  <div>
                    <div style={{ boxShadow: "none" }}>
                      <div style={{ overflowX: "auto" }}>
                        <table
                          style={{ boxShadow: "initial", textAlign: "justify" }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <h1 style={{ marginBottom: "3%" }}>
                                  Habitación
                                </h1>
                              </th>
                            </tr>
                            <tr>
                              {habitacion.disponible
                                ? "Disponible"
                                : "No disponible"}
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "-webkit-fill-available",
                      minHeight: "69vh",
                      padding: "1.8rem",
                    }}
                  >
                    <div
                      style={
                        matches
                          ? { display: "grid", gridTemplateColumns: "50% 50%" }
                          : { display: "flex", flexDirection: "column" }
                      }
                    >
                      <div>
                        <div style={{ padding: "0.5rem" }}>
                          <TextField
                            id="filled-basic"
                            label="Tipo"
                            fullWidth
                            value={habitacion?.tipo}
                            variant="standard"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        {habitacion?.cama?.map((cama, index) => (
                          <div key={index} style={{ padding: "0.5rem" }}>
                            <TextField
                              id="filled-basic"
                              label="Cama"
                              value={cama?.cama}
                              variant="standard"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <TextField
                              id="filled-basic"
                              label="Ocupantes"
                              value={cama?.ocupantes}
                              variant="standard"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          padding: "0.5rem",
                          overflow: "auto",
                          height: "20rem",
                        }}
                      >
                        <div
                          style={{
                            width: "-webkit-fill-available",
                            marginTop: "2rem",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "grid",
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "33% 33% 33%",
                        }}
                      >
                        <div
                          style={{
                            padding: "0.5rem",
                          }}
                        >
                          <TextField
                            id="filled-basic"
                            label="Precio"
                            value={habitacion?.precio}
                            variant="standard"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <div>MXN/c.Noche</div>
                        </div>
                        <div style={{ padding: "0.5rem" }}>
                          <TextField
                            id="filled-basic"
                            label="Habitaciones"
                            value={habitacion?.habitaciones}
                            variant="standard"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <div>Habitaciones</div>
                        </div>
                        <div style={{ padding: "0.5rem" }}>
                          <TextField
                            id="filled-basic"
                            label="Cupo"
                            value={habitacion?.cupo}
                            variant="standard"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <div>Personas/Habitacion</div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <p
                          style={{
                            width: "100%",
                            borderBottom: "1px solid #8b8b8b",
                            textAlign: "center",
                            fontSize: "1.3rem",
                          }}
                        >
                          Amenidades
                        </p>
                        {habitacion?.amenidades?.map((amenidad, index) => (
                          <div
                            key={index}
                            style={{ minWidth: "20rem", padding: "0.5rem" }}
                          >
                            <h3
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {amenidad?.label}
                            </h3>
                            <ul
                              id="transition-modal-description"
                              style={{ marginTop: 16, paddingLeft: "0.6em" }}
                            >
                              {amenidad?.options?.map((option, index) => (
                                <li
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FiberManualRecordIcon sx={{ fontSize: 6 }} />{" "}
                                  <span style={{ paddingInline: "0.5rem" }}>
                                    {option.text}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleClose()}
                        style={{ margin: "5px" }}
                      >
                        Regresar
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => reservar()}
                        style={{ margin: "5px" }}
                      >
                        Reservar
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          </StyledModal>
        </div>
      )}
    </>
  );
};

export default HabitacionModal;

const Backdrop = forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const blue = {
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  display: "flex",
  flexWrap: "wrap",
  minWidth: "30rem",
  overflowY: "auto",
  height: "98%",
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#e3036d",
  backgroundColor: "white",
  borderColor: "#e3036d",
}));
