import React from "react";

const Titulo = () => {
  return (
    <section className="page-title">
      <div
        className="page-title-img bg-img bg-overlay-darken"
        style={{ backgroundImage: "url(assets/img/pages/page-title-bg6.jpg)" }}
      >
        <div className="containerPublic">
          <div
            className="row align-items-center justify-content-center"
            style={{ height: 200 }}
          >
            <div className="col-lg-6">
              <div className="page-title-content">
                <div className="title-border">
                  <h2 className="text-uppercase text-white font-weight-bold">
                    Nuestros paquetes
                  </h2>
                </div>
                <p className="text-white mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Titulo;
