import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../../context/AuthContext";
import S3Service from "../../../services/S3.service";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "../layout.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px",
  boxShadow: 24,
  p: 4,
};

const Header = () => {
  const [tema, setTema] = useState("white-theme-variables");
  const { currentUser, logout } = useContext(AuthContext);
  let navigate = useNavigate();
  const [imag, setImag] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let element;
    element = document.querySelector("#tema");
    element.className = tema;
    getImagen();
  }, []);

  const salir = () => {
    logout();
    navigate("/");
  };

  const changeTheme = () => {
    let element;
    element = document.querySelector("#tema");
    if (tema == "dark-theme-variables") {
      setTema("white-theme-variables");
    } else {
      setTema("dark-theme-variables");
    }
    element.className = tema;
  };

  const getImagen = async () => {
    try {
      if (currentUser.imagen != undefined) {
        const result = await S3Service.get(currentUser.imagen);
        setImag(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayMenu = () => {
    let element;
    element = document.querySelector("#aside_responsive");
    element.style.display = "block";
  };

  return (
    <>
      <div
        className={styles.right}
        id="headerPrivado"
        style={{ backgroundColor: "#4B8C0E", padding: "3%" }}
      >
        <div className={styles.top}>
          <button onClick={displayMenu}>
            <span>
              <svg width={25} height={25} viewBox="0 0 20 20">
                <path d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z" />
                <path d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z" />
                <path d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z" />
                <path d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z" />
              </svg>
            </span>
          </button>
          <div className={styles.profile}>
            <div className={styles.info}>
              <a onClick={handleOpen} href="javascript:void(0)">
                <p style={{ color: "white" }}>Bienvenido</p>
                <a href="javascript:void(0)">
                  <small
                    className={styles.textmuted}
                    style={{ color: "white" }}
                  >
                    {currentUser.nombre}
                  </small>
                </a>
              </a>
            </div>
            <div className={styles.profilephoto}>
              <a onClick={handleOpen} href="javascript:void(0)">
                <img
                  src={
                    currentUser.imagen == undefined
                      ? require("./assets/user.png")
                      : imag
                  }
                  alt="Profile Picture"
                  style={{ height: "3rem" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: "absolute" }}
      >
        <Box
          sx={style}
          style={{
            overflow: "auto",
            textOverflow: "ellipsis",
            maxHeight: "500px",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div style={{ width: "100%" }} className={styles.row}>
              {currentUser.nombre}
              <div
                style={{
                  textAlign: "right",
                  marginTop: "-12%",
                  marginRight: "-3%",
                }}
              >
                <button
                  type="button"
                  onClick={() => handleClose()}
                  style={{ padding: "3%" }}
                >
                  X
                </button>
              </div>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <img
              src={
                currentUser.imagen == undefined
                  ? require("./assets/user.png")
                  : imag
              }
              alt="Profile Picture"
              style={{ borderRadius: "50%", padding: "10%" }}
            />
          </Typography>
          <div style={{ width: "100%", textAlign: "center" }}>
            <a href="">
              <button
                type="submit"
                onClick={() => salir()}
                style={{
                  border: "0",
                  backgroundColor: "#e9036e",
                  padding: "5%",
                  color: "white",
                  width: "100%",
                }}
              >
                <span>
                  <i className="fa fa-power-off mr-0" aria-hidden="true" />
                </span>
              </button>
            </a>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Header;

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));
