import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserService from "../../../services/User.Service";
import FullPageLoading from "../../FullPageLoading";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DropZone from "../../utils/DropZoneDos";
import ReCAPTCHA from "react-google-recaptcha";
import S3Service from "../../../services/S3.service";
import ForgotPasswordService from "../../../services/ForgotPassword.service";
import { validarContrasena } from "../../../utils/Validator";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px",
  boxShadow: 24,
  p: 4,
};

const ResetPassword = () => {
  const { login } = useContext(AuthContext);
  const [datos, setDatos] = useState({ correo: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFile("");
  };
  const [file, setFile] = useState(undefined);
  const [valido, setValido] = useState(true);

  const [success, setSuccess] = useState(false);
  const [validarToken, setValidarToken] = useState(false);
  const [registro, setRegistro] = useState({
    contraseña: "",
    contraseñaConfirma: "",
  });
  const { id, token } = useParams();
  const statusContrasena = registro.contraseña
    ? validarContrasena(registro.contraseña)
    : "";
  const contrasenaConincide =
    statusContrasena === "La contraseña es segura" && "success";
  const errorContrasena =
    statusContrasena !== "La contraseña es segura" && statusContrasena;

  const onSumit = () => {
    if (!registro || !registro.contraseña) {
      toast.info("Error, tienes que colocar una contraseña");
      return;
    }
    if (!registro.contraseñaConfirma) {
      toast.info("Error, tienes que rellenar todos los campos");
      return;
    }
    if (registro.contraseñaConfirma !== registro.contraseña) {
      toast.info("Error, las contraseñas no coinciden");
      return;
    }

    let contrasenaValidada = validarContrasena(registro.contraseña);
    if (contrasenaValidada !== "La contraseña es segura") {
      toast.info(contrasenaValidada);
      return;
    }

    setLoading(true);
    const data = {
      id: id,
      token: token,
      data: { contraseña: registro.contraseña },
    };

    try {
      const promise = ForgotPasswordService.resetPassword(data).then(
        (response) => {
          if (response !== "Not Verified") {
            setDatos(response);
            setSuccess(true);
            setValidarToken(false);
          }
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(false);
  }

  const validateToken = () => {
    const data = {
      id: id,
      token: token,
    };

    try {
      const promise = ForgotPasswordService.valideTokenPassword(data).then(
        (response) => {
          if (response === "Not Verified") {
          } else {
            setDatos(response);
            setValidarToken(true);
          }
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div
            className="main-wrapper blog-grid-three-col"
            style={{ marginBottom: "-6.5%" }}
          >
            <section className="py-9 py-md-10" style={{ marginTop: "3rem" }}>
              <div className="containerPublic">
                <div className="row">
                  <div className="col-lg-7 col-xl-7">
                    <div style={{ marginLeft: "-16%", marginTop: "-1%" }}>
                      <img
                        data-src="assets/img/blog/tab-01.jpg"
                        src="https://images.unsplash.com/photo-1588623570373-24d3f3e3e3fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHVlcnRvJTIwZXNjb25kaWRvfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                        alt="Generic placeholder"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>

                  <div className="col-lg-5 col-xl-5 order-1">
                    <Grid>
                      <Card
                        style={{
                          maxWidth: 450,
                          padding: "20px 5px",
                          margin: "0 auto",
                        }}
                      >
                        <CardContent>
                          <Typography gutterBottom variant="h5">
                            <h2 className="text-uppercase mb-5">
                              Recuperar contraseña
                            </h2>
                          </Typography>

                          <div>
                            <Grid containerPublic spacing={1}>
                              <div style={{ justifyContent: "center" }}>
                                {loading ? (
                                  <div>
                                    <div className="mb-4 mb-sm-0">
                                      <div
                                        className="mb-3 border p-3 rounded"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CircularProgress color="inherit" />
                                      </div>
                                    </div>
                                  </div>
                                ) : validarToken ? (
                                  <div>
                                    <div className="mb-4 mb-sm-0">
                                      <div className="bg-primary rounded-top p-2">
                                        <h3 className="text-white font-weight-bold mb-0 ms-2">
                                          Ingresa tu nueva contraseña
                                        </h3>
                                      </div>
                                      <div className="border rounded-bottom-sm border-top-0">
                                        <div className="p-3">
                                          <form>
                                            <div className="form-group form-group-icon">
                                              <TextField
                                                style={{ width: "100%" }}
                                                error={errorContrasena}
                                                color={contrasenaConincide}
                                                label="Contraseña"
                                                type="password"
                                                defaultValue=""
                                                helperText={statusContrasena}
                                                onChange={(e) =>
                                                  setRegistro({
                                                    ...registro,
                                                    contraseña: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                            <div className="form-group form-group-icon">
                                              <TextField
                                                style={{ width: "100%" }}
                                                error={
                                                  registro.contraseña !==
                                                  registro.contraseñaConfirma
                                                }
                                                label="Confirmar contraseña"
                                                defaultValue=""
                                                type="password"
                                                helperText={
                                                  registro.contraseña !==
                                                  registro.contraseñaConfirma
                                                    ? "Las contraseñas no coinciden"
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  setRegistro({
                                                    ...registro,
                                                    contraseñaConfirma:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </form>
                                          <div className="form-group">
                                            <button
                                              className="btn btn-danger text-uppercase w-100"
                                              onClick={() => onSumit()}
                                            >
                                              Recuperar contraseña
                                            </button>
                                          </div>
                                          <div className="form-group text-center text-secondary mb-0">
                                            <button
                                              className="text-danger"
                                              style={{
                                                border: "none",
                                                backgroundColor: "unset",
                                              }}
                                              onClick={() => navigate("/login")}
                                            >
                                              Iniciar sesión o Registrarse
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    {success ? (
                                      <div className="mb-4 mb-sm-0">
                                        <div
                                          className="mb-3 border p-3 rounded"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          Se ha restablecido tu contraseña
                                        </div>
                                        <div className="form-group text-center text-secondary mb-0">
                                          <button
                                            className="text-danger"
                                            style={{
                                              border: "none",
                                              backgroundColor: "unset",
                                            }}
                                            onClick={() => navigate("/login")}
                                          >
                                            Iniciar sesión
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div
                                          className="mb-3 border p-3 rounded"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          Este token no es valido o ha expirado!
                                        </div>
                                        <div className="form-group text-center text-secondary mb-0">
                                          <button
                                            className="text-danger"
                                            style={{
                                              border: "none",
                                              backgroundColor: "unset",
                                            }}
                                            onClick={() => navigate("/")}
                                          >
                                            Inicio
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </Grid>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default ResetPassword;
