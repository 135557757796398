import React , { useEffect }  from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Slide from "./Slide";
import styles from "../layout.module.css";

const PrivateLayout = () => {
  
  return (
    <body id="tema" className="white-theme-variables">
      <div className={styles.container} id="containerZoom">
        <Slide />
        <main>
          <Header />
          <Outlet />
        </main>
      </div>
    </body>
  );
};

export default PrivateLayout;
